<div>
    <mat-card class="no-shadow px-0" style="max-width: 600px;">
        <mat-card-header class="px-1">
            <div mat-card-avatar class="avatar-modal-process-anwers">
                <mat-icon color="white">pending_actions</mat-icon>
            </div>
            <mat-card-title>{{'PROCESS_PENDING_ANSWERS.TITLE'|trans}}</mat-card-title>
            <mat-card-subtitle>{{'PROCESS_PENDING_ANSWERS.SUBTITLE'|trans}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="mb-0">
            <mat-divider></mat-divider>
            <!-- progress bar -->
            <div fxLayout="row">
                <mat-progress-bar mode="determinate" [value]="getProgressPercentage()"  *ngIf="start_processing"></mat-progress-bar>
            </div>
            <div fxLayout="row wrap" class="wp-100">
                <!-- proccess status and filters -->
                <div fxLayout="row" class="py-2 px-1" fxFlex="1 0 50%" fxFlex.lt-md="100%" fxLayoutAlign="center center" *ngIf="start_processing">
                    <mat-chip-list>
                        <mat-chip color="primary" (click)="chipSelected('schedule')">
                            <mat-chip-avatar>
                                <mat-icon>schedule</mat-icon>
                            </mat-chip-avatar>
                            <strong>{{total_pending_answers}}</strong>
                        </mat-chip>
                        <mat-chip color="success" selected (click)="chipSelected('check_circle')">
                            <mat-chip-avatar>
                                <mat-icon>check_circle</mat-icon>
                            </mat-chip-avatar>
                            <strong>{{count_completed_answers}}</strong>
                        </mat-chip>
                        <mat-chip color="warn" selected (click)="chipSelected('error')" *ngIf="processed_with_errors">
                            <mat-chip-avatar>
                                <mat-icon>error</mat-icon>
                            </mat-chip-avatar>
                            <strong>{{count_error_answers}}</strong>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <!-- answers list -->
                <div fxLayout="row" class="wp-100 list-container">
                    <mat-list class="wp-100 pt-0" #pends>
                        <ng-container *ngFor="let pend_ans of tmp_pending_answers_filter; let i = index">
                            <mat-list-item [value]="pend_ans">
                                <mat-icon mat-list-icon color="darkgrey">{{ getStatusIcon(pend_ans.status) }}</mat-icon>
                                <div mat-line>{{ formatRespondentName(pend_ans.respondent_data.name) }}</div>
                                <div mat-line> {{ pend_ans.created_at | date: 'd/M/yy, h:mm a' }} </div>
                                <mat-icon class="delete-icon" mat-list-icon *ngIf="pend_ans.status == 2" (click)="deletePendingAnswer(pend_ans, i)">delete</mat-icon>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </div>
                <!-- <div fxLayout="row" class="py-2 px-1" fxFlex="100%" fxLayoutAlign="center center" *ngIf="nothing_to_process">
                    <span class="close-alert-message wp-100">{{'PROCESS_PENDING_ANSWERS.NOTHING_MSG'|trans}}</span>
                </div> -->
                <!-- *ngIf="start_processing" -->
                <!-- <div fxLayout="row" class="py-2 px-1" fxFlex="100%" fxLayoutAlign="center center" *ngIf="start_processing">
                    <span class="close-alert-message wp-100">{{'PROCESS_PENDING_ANSWERS.FOOTER_MSG'|trans}}</span>
                </div> -->
            </div>
        </mat-card-content>
        <mat-card-actions class="pt-0">
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="pt-1 px-2">
                <div fxLayout="column" fxLayoutAlign="start start">
                    <button mat-button class="btn active"
                        (click)="evaluateAction(false)">{{'COMMON.CLOSE'|trans}}</button>
                </div>
                <div fxLayout="column" fxLayoutAlign="end start">
                    <button mat-flat-button color="primary" class="btn active" (click)="evaluateAction(true)" [disabled]="disable">{{ !processed_with_errors ? ('PROCESS_PENDING_ANSWERS.PROCESS_NOW'|trans) : ('PROCESS_PENDING_ANSWERS.PROCESS_AGAIN'|trans) }}</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>