import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snack: MatSnackBar) { }
  /**
   * 
   * @param title 
   * @param action 
   * @param classes 
   * @param horizontalPosition 
   * @param verticalPosition 
   * @returns MatSnackBarRef
   */
  openSnack(title: string, action: string, horizontalPosition: MatSnackBarHorizontalPosition = 'right', verticalPosition: MatSnackBarVerticalPosition = 'top', panelClass: Array<string> = null, duration: number = 0) {
    return this.snack.open(title, action, {
      panelClass: panelClass,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      duration: duration
    });
  }
}
