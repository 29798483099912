import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
// env
import { environment } from 'src/environments/environment';
// services
import { SurveyService } from './survey.service';
import { RouterService } from './router.service';
import { QservusApiService } from './qservus-api.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateWorkerService {

  public updateNeeded: boolean = false;

  auto_update_sub: Subscription;

  constructor(
    private swUpdate: SwUpdate,
    private surveyService: SurveyService,
    private qservusApiService: QservusApiService,
    // private location: Location,
    private router: Router,
    private routerService: RouterService) {


    // console.log('init swUpdate service');
    if (!this.swUpdate.isEnabled) {
      console.log('update service not available 🙁');
      return;
    } else {

      this.checkUpdateAvailibleAuto();
      swUpdate.checkForUpdate();

      setTimeout(() => { // wait 5 secs, first must be executed the auto update
        this.checkUpdateAvailable();
        // console.log("iniciando suscripción a swUpdate.available");
      }, 5500);

      interval(6000).subscribe(() => {
        if (this.updateNeeded) this.loadNewVersion();
        else swUpdate.checkForUpdate();
      });
    }

  }
  checkUpdateAvailable() {
    this.auto_update_sub?.unsubscribe();

    this.swUpdate.available.subscribe(event => {
      // console.log('current version is', event.current);
      // console.log('available version is', event.available);
      this.updateNeeded = true;
      this.loadNewVersion();
      // sb.unsubscribe();
    });
  }
  /**
   * 
   */
  checkUpdateAvailibleAuto() {
    this.auto_update_sub = this.swUpdate.available.subscribe(event => {
      this.swUpdate.activateUpdate();
      window.location.reload();
    });

  }
  // https://surv-qa.qservus.com/?uuid4=294cba0e-4994-40ab-bfeb-3cc7880ed891
  loadNewVersion() {

    // console.log("this.surveyService.answers.length", this.surveyService.answers.length);

    if (this.surveyService.answers.length == 0) {

      if (!this.router.url.includes('thanks')) {
        this.swUpdate.activateUpdate();
        window.location.reload();
      }

    }
  }
}
