import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  public change_message: BehaviorSubject<string>;

  constructor() { 
    this.change_message = new BehaviorSubject("");
  }
}
