<div>
    <mat-card class="no-shadow" style="max-width: 600px;">
        <mat-card-header>
            <div mat-card-avatar class="avatar-modal-confirm">
                <mat-icon color="white">info</mat-icon>
            </div>
            <mat-card-title>{{title}}</mat-card-title>
            <mat-card-subtitle *ngIf="subtitle != ''">{{subtitle}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content *ngIf="message != ''">
            <mat-divider></mat-divider>
            <div class="pt-1 text-center">
                <h2 class="mt-0">{{message}}</h2>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="pt-1">
                <div fxLayout="column" fxLayoutAlign="start start">
                    <button mat-button class="btn active" (click)="emitAction(false)">{{button_no}}</button>
                </div>
                <div fxLayout="column" fxLayoutAlign="end start">
                    <button mat-flat-button color="primary" class="btn active" (click)="emitAction(true)">{{button_yes}}</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>