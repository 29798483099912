import Dexie, { Table } from 'dexie';
import { PendingAnswers } from '../models/pending-answers';
import { TemporaryAnswers } from '../models/temporary-answers';

export class AppDB extends Dexie {
    pendingAnswers!: Table<PendingAnswers>
    temporaryAnswers!: Table<TemporaryAnswers>

    constructor() {
        super('ngdexieliveQuery');
        this.version(4).stores({
            // pending answers means: answers saved in offline mode ("pending" to be POSTED)
            pendingAnswers: '++id, su_survey_id, qs_sensor_id, qs_campaign_id, ip, user_agent, answers, extra_data, respondent_data, created_at, status',
            // temporary answers means: answers saved while answering a survey, can later be processed as partial answers in QServus
            temporaryAnswers: '++id, survey_uuid, answers, created_at'
        });
        this.open();
            
    }
    /**
     * Get a register by id in any table
     * @param table used to call get method
     * @param id primary key of the object to get
     * @returns Object found
     * @author Daniel García Silva
     * @date 2022-11-30
     */
    async _get(table: string, id: number) {
        return await db.table(table).get(id);
    }
    /**
     * Get registers found by any key in any table
     * @param table used to call get method
     * @param key property name use in where clausule
     * @param value value to filter
     * @returns List of objects found
     * @author Daniel García Silva
     * @date 2022-11-30
     */
    async _getByKey(table: string, key: string, value: string | number) {
        return db.table(table).where(key).equals(value).toArray();
    }
    /**
     * Create a new register in any table
     * @param table used to call add method
     * @param obj object to add
     * @returns the identifier of the new record
     * @author Daniel García Silva
     * @date 2022-11-30
     */
    async _add(table: string, obj: PendingAnswers | TemporaryAnswers) {
        return await db.table(table).add(obj);
    }
    /**
     * Update a register in any table
     * @param table used to call update method
     * @param id primary key of the object to update
     * @param obj object data
     * @returns the identifier of the updated record
     * @author Daniel García Silva
     * @date 2022-11-30
     */
    async _update(table: string, id: number, obj: PendingAnswers | TemporaryAnswers) {
        return await db.table(table).update(id, obj);
    }
    /**
     * Delete a register in any table
     * @param table used to call delete method
     * @param id primary key of the object to delete
     * @returns undefined
     * @author Daniel García Silva
     * @date 2022-11-30
     */
    async _delete(table: string, id: number) {
        return await db.table(table).delete(id);
    }
    /**
     * Delete registers found by any key in any table
     * @param table used to call delete method
     * @param key property name use in where clausule
     * @param value value to filter
     * @returns undefined
     * @author Daniel García Silva
     * @date 2022-11-30
     */
    async _deleteByKey(table: string, key: string, value: string | number) {
        return await db.table(table).where(key).equals(value).delete();
    }
}

export const db = new AppDB();