import { Injectable } from '@angular/core';
// models
import { SensorField } from '../models/sensor-field';
// services
import { CalendlyService } from './calendly.service';
import { FormValidatorService } from './form-validator.service';

@Injectable({
  providedIn: 'root'
})
export class SensorFormService {

  public fields_list: Array<SensorField> = [];

  constructor(
    private formValidatorService: FormValidatorService) { }

  setSurveyFieldList(sensor_fields: any, default_fields: any) {
    this.fields_list = [];
    
    // check default fields
    if (default_fields.name_visible) { // name field
      this.fields_list.push({
        "label": 'NAME',
        "visibility": true,
        "mandatory": default_fields.name_mandatory,
        "validation_type": 'GENERAL'
      })
    }
    if (default_fields.email_visible) { // email field
      this.fields_list.push({
        "label": 'EMAIL',
        "visibility": true,
        "mandatory": default_fields.email_mandatory,
        "validation_type": 'EMAIL'
      })
    }
    if (default_fields.phone_visible) { // phone field
      this.fields_list.push({
        "label": 'PHONE',
        "visibility": true,
        "mandatory": default_fields.phone_mandatory,
        "validation_type": 'PHONE'
      })
    }
    // check sensor fields
    if (sensor_fields != null) {
      if (sensor_fields.length) {
        sensor_fields.forEach((sensor_field) => {

          this.fields_list.push({
            "label": sensor_field.label,
            "validation_type": this.formValidatorService.validation_id_to_type[sensor_field.validation_type],
            "mandatory": sensor_field.mandatory,
            "answer": sensor_field.value,
            "visibility": sensor_field.visible,
            "extra_data": true
          })
          if (sensor_field.label.includes('calendly')) {
            this.fields_list[this.fields_list.length - 1].validation_type = "CALENDLY";
          }

        })
      }
    }
    return this.fields_list;
  }
}
