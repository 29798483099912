import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// models
import { PendingAnswers } from 'src/app/models/pending-answers';
import { Answer } from 'src/app/models/answer';
// components
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
// services
import { NetworkService } from 'src/app/services/network.service';
import { DbService } from 'src/app/services/db.service';
import { QservusApiService } from 'src/app/services/qservus-api.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SurveyService } from 'src/app/services/survey.service';
import { TranslateService } from 'src/app/services/translate.service';
import { TransPipe } from 'src/app/shared/pipes/trans.pipe';

@Component({
  selector: 'app-modal-process-pending-answers',
  templateUrl: './modal-process-pending-answers.component.html',
  styleUrls: ['./modal-process-pending-answers.component.scss']
})
export class ModalProcessPendingAnswersComponent implements OnInit {

  tmp_pending_answers: Array<PendingAnswers> = [];
  tmp_pending_answers_filter: Array<PendingAnswers> = [];

  total_pending_answers: number = 0;
  count_proccessed_answers: number = 0;
  count_completed_answers: number = 0;
  count_error_answers: number = 0;
  selected_filter: number = 0;

  start_processing: boolean = false; // change to false
  loading: boolean = false;
  disable: boolean = false; // change to false;
  processed_with_errors: boolean = false; // change to false
  progress_goal: number = 0;

  nothing_to_process: boolean = false;

  status_list = {
    "0": 'schedule',
    "1": 'check_circle',
    "2": 'error'
  }
  constructor(
    public dialogRef: MatDialogRef<ModalProcessPendingAnswersComponent>,
    private surveyService: SurveyService,
    private qservusApiService: QservusApiService,
    private trans: TranslateService,
    private matDialog: MatDialog,
    private dbService: DbService,
    private networkService: NetworkService,
    private snack: SnackbarService,
    private transPipe: TransPipe) { }

  ngOnInit(): void {
    this.tmp_pending_answers = this.surveyService.pending_answers;
    this.tmp_pending_answers_filter = this.tmp_pending_answers;
    this.total_pending_answers = this.tmp_pending_answers.length;

    // this.total_pending_answers = 0;
    // this.count_completed_answers = 2;
    // this.count_proccessed_answers = 3;
    // this.tmp_pending_answers[0].status = 1;
    // this.tmp_pending_answers[1].status = 1;
    // this.tmp_pending_answers[2].status = 2;

    // this.count_error_answers = 1;
    // this.snack.openSnack(this.transPipe.transform('PROCESS_PENDING_ANSWERS.END_ERROR'), this.transPipe.transform('COMMON.CLOSE'), 'right', 'top', ['snack-qservus-blue'], 5000);

    if (this.tmp_pending_answers.filter(tpa => tpa.status == 0 || tpa.status == 2).length == 0) {
      this.disable = true;
      this.nothing_to_process = true;
      this.snack.openSnack(this.transPipe.transform('PROCESS_PENDING_ANSWERS.NOTHING_MSG'), this.transPipe.transform('COMMON.OK'), 'right', 'top', ['snack-qservus-blue'], 5000);
    }
    this.networkService.status_sub.subscribe((status) => {
      if (!status) this.closeModal(false);
    })
  }
  evaluateAction(process: boolean) {
    if (process) this.processAnswers();
    else this.closeModal(false);
  }
  /**
  * Save answers + upload files if exists
  */
  async processAnswers() {
    this.loading = true;
    this.disable = true;
    this.processed_with_errors = false;
    this.start_processing = true;
    this.progress_goal = this.tmp_pending_answers.filter(tpa => tpa.status == 0 || tpa.status == 2).length;

    for (const [i, a] of this.tmp_pending_answers.entries()) {

      let pending_ans = a as PendingAnswers;

      if (pending_ans.status == 0 || pending_ans.status == 2) { // do not consider successful submissions: 1
        // const has_files = await this.surveyService.hasFileQuestions(pending_ans.answers);
        if (this.answersHasFiles(pending_ans)) {

          this.surveyService.processFileAnswers(pending_ans.answers).then((resp) => {
            // format file answers to only send URL
            pending_ans.answers.filter((a) => a.answer['file'] != undefined).forEach(a => a.answer = a.answer.url);

            this.qservusApiService.sendAnswer(pending_ans).then((post_answers_resp) => {
              this.handdleSuccess(post_answers_resp, i);
            }, error => this.handleError(i, error))

          })

        } else {

          this.qservusApiService.sendAnswer(pending_ans).then((post_answers_resp) => {
            this.handdleSuccess(post_answers_resp, i);
          }, error => this.handleError(i, error))

        }
      }
    }
  }
  /**
   * handle success response on request
   * @param resp 
   * @param index 
   */
  handdleSuccess(resp: any, index: number) {
    this.count_proccessed_answers++;
    if (resp.uuid != undefined) { // update counters
      this.count_completed_answers++;
      this.tmp_pending_answers[index].status = 1;
      this.total_pending_answers = this.tmp_pending_answers.filter(tpa => tpa.status == 0).length;

      this.dbService.updatePendingAnswers(this.tmp_pending_answers[index].id, this.tmp_pending_answers[index])
    }
    if (index == this.tmp_pending_answers.length - 1) { // check if it´s the last one
      this.loading = false;
      this.disable = false;
      if (this.tmp_pending_answers.filter(tpa => tpa.status == 0 || tpa.status == 2).length == 0) this.disable = true;
      this.endProcess();
    }
  }
  /**
   * handle error on request
   * @param index 
   * @param error 
   */
  handleError(index: number, error: any) {
    console.log("error sendAnswer =", error);
    this.processed_with_errors = true;
    this.count_proccessed_answers++;
    this.tmp_pending_answers[index].status = 2;
    this.total_pending_answers = this.tmp_pending_answers.filter(tpa => tpa.status == 0).length;

    this.dbService.updatePendingAnswers(this.tmp_pending_answers[index].id, this.tmp_pending_answers[index])

    this.count_error_answers++;
    if (index == this.tmp_pending_answers.length - 1) { // check if it´s the last one
      this.loading = false;
      this.disable = false;
      if (this.tmp_pending_answers.filter(tpa => tpa.status == 0 || tpa.status == 2).length == 0) this.disable = true;
      this.endProcess();
    }
  }
  /**
   * show message to notice the user proccess is completed succesfully or with errors
   * - no errors = close modal after 5 seconds
   * - errors = do nothing
   */
  endProcess() {
    if (this.disable) {
      const snack = this.snack.openSnack(this.transPipe.transform('PROCESS_PENDING_ANSWERS.END_FULL'), this.transPipe.transform('COMMON.OK'), 'right', 'top', ['snack-qservus-blue'], 5000);

      const subAct = snack.onAction().subscribe(() => {
        this.closeModal(true);
      })
      const subTime = snack.afterDismissed().subscribe(() => {
        this.closeModal(true);
        subAct.unsubscribe();
        subTime.unsubscribe();
      })
    } else this.snack.openSnack(this.transPipe.transform('PROCESS_PENDING_ANSWERS.END_ERROR'), this.transPipe.transform('COMMON.CLOSE'), 'right', 'top', ['snack-qservus-blue'], 5000);
  }

  /**
   * 
   * @param pending_ans 
   * @returns 
   */
  answersHasFiles(pending_ans: PendingAnswers): boolean {
    const finded_with_file = pending_ans.answers.find((a: Answer) => a.answer['file']);
    return finded_with_file != undefined;
  }
  /**
   * get current process progress
   * @returns progress percentage
   */
  getProgressPercentage() {
    // console.log((this.count_completed_answers / this.progress_goal) * 100);
    if (this.count_proccessed_answers == 0) return 0;
    else return (this.count_proccessed_answers / this.progress_goal) * 100;
  }
  closeModal(value: boolean) {
    this.dialogRef.close({ status: value });
  }
  /**
   * get respondent name if exists
   * @param name 
   * @returns name or anonymous text
   */
  formatRespondentName(name: string) {
    if (name != undefined) {
      return (name != "") ? name : this.transPipe.transform('PROCESS_PENDING_ANSWERS.ANONYMOUS');
    } else return this.transPipe.transform('PROCESS_PENDING_ANSWERS.ANONYMOUS');

  }
  /**
   * filter answers list
   * @param status 
   */
  chipSelected(status: number) {
    this.selected_filter = status;
    this.tmp_pending_answers_filter = this.tmp_pending_answers.filter((pa: PendingAnswers) => pa.status == this.selected_filter);
  }
  /**
   * manual deletion for pending answers with errors
   * @param pendingAns 
   * @param index 
   */
  deletePendingAnswer(pendingAns: PendingAnswers, index: number) {

    this.matDialog.open(ModalConfirmComponent, {
      data: {
        title: this.transPipe.transform('PROCESS_PENDING_ANSWERS.MODAL_DELETE_PEND_TITLE'),
        subtitle: ' ',
        // subtitle: this.trans.data['MODAL_DELETE_PEND_SUBTITLE'],
        message: '',
        button_no: this.transPipe.transform('COMMON.NO'),
        button_yes: this.transPipe.transform('PREVIEW.CONFIRM')

      },
      disableClose: true,
      panelClass: "modal-confirm"
    }).afterClosed().subscribe((resp) => {
      if (resp) { // delete pending answer
        this.tmp_pending_answers.splice(index, 1);
        this.tmp_pending_answers_filter = this.tmp_pending_answers;
        this.dbService.deletePendingAnswer(pendingAns.id).then((resp) => {
          //  console.log("resp dbService deletePendingAnswer", resp);
        })
      }
    })

  }
  /**
   * retrieve icon name of the pending answer status
   * @param status 
   */
  getStatusIcon(status: number) {
    return this.status_list[status];
  }
}
