export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBO5gHuoT3SSGHqCcjrKo-XKxCWNUOBgTo",
    authDomain: "qservus-surv-demo.firebaseapp.com",
    projectId: "qservus-surv-demo",
    storageBucket: "qservus-surv-demo.appspot.com",
    messagingSenderId: "264244384163",
    appId: "1:264244384163:web:b2a7cf7ad05c14daac4a16",
    measurementId: "G-DEK83BVRR0"
  },
  awsBucket: {
    region: "us-east-1",
    bucket_name: "qservus.demo",
    folder: "surv/",
    domain: "https://s3.us-east-1.amazonaws.com/qservus.demo",
    custom_domain: "https://dh4fyf9axoztv.cloudfront.net",
    identityPoolId: "us-east-1:9bdc1be1-f804-424c-bb32-07842f1fd5b3"
  },
  mongoAPI: 'http://127.0.0.1:8080/api/qss',
  sentry_enable: true,
  sentry_environment: "demo",
  sentry_tracingOrigins: ["https://surv-demo.qservus.com/"],
  qsApiUrl: 'https://demo.qservus.com/api/',
  qsApiQSSUrl: 'https://demo.qservus.com/api/qss/',
  qsBaseUrl: 'https://demo.qservus.com',
  qsToken: 'Token 4560d8c61055a292bef4f9de5aa59536d86d7784', // DEMO
  passCrypt: "9js!2d4^^%^009870^*jkvcT%74*AS(639.3$Fcma_sdXZfJ*",
  recaptcha: {
    siteKey: '6Le2KwweAAAAAIeH29r5xvDQt_Ou7griSCsMoTys',
  },
  survPageURL: "https://surv-demo.qservus.com/",
  qservusGlobalAuth: {
    user: "dgarcia@qservus.com",
    pass: "&Fnttm1Ot2LMewImk^ky3y1r"
  },
  defaultBanner: "https://cdn-qservus.redcalidad.com/img/banner_qs_blue.png",
  calendly: {
    dgarsil: "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjU1MzA2NjMyLCJqdGkiOiI0MWIxN2UwNS02ZGRiLTRiYmYtYTM0Ni1hYzA4MmYxZDc4MjYiLCJ1c2VyX3V1aWQiOiI5NTM5ZDVjYy05OWIzLTRkZDMtYTkzMS02ZmFhOGFjNmNlOGIifQ._2fzUkMKOmqpSQ_d1NUdjJpOHK1ic31HvqCO05A4vx8",
    hlobos: ""
  }
};
