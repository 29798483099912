import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// service
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("logged") == "true") {
      // get user data
      const user = JSON.parse(localStorage.getItem("qservus-surv-user-data"));
      if (user != undefined) {
        // check if PIN code is set
        // console.log("guard user ==", user);
        if (user.pin != undefined) {
          // check if PIN code has expired
          const p_data = JSON.parse(localStorage.getItem("pin_validated"));
          if (p_data != undefined) {
            if (p_data.until > new Date().getTime()) return true;
            else this.router.navigate(['request-pin'], { queryParams: { 'redirect': state.url } });
          } else this.router.navigate(['request-pin'], { queryParams: { 'redirect': state.url } });
          return true;
        } else return true;
      }
    }
    else this.router.navigate(['login']);
  }
}
