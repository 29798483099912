import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
// custom validations
import {
  acceptedTypesValidator,
  equalToValidator,
  maxSelectValidator,
  minSelectValidator,
  requieredSelectMultiValidator,
  requieredSelectSingleValidator,
  requieredMatrixSelectMultiValidator,
  placeAutocompleteValidator,
  rutValidator,
  comboboxRequiredValueValidator3,
  phoneNumberValidator,
  // comboboxRequiredValueValidator,
  // rutValidator
} from 'src/app/validators/common-validations';
// pipes
import { ThousandsPipe } from '../shared/pipes/thousands.pipe';
// models
import { SensorField } from '../models/sensor-field';
import { Question } from '../models/question';
// services
import { TranslateService } from './translate.service';
import { SurveyService } from './survey.service';
import { NetworkService } from './network.service';
import { TransPipe } from '../shared/pipes/trans.pipe';
// import { RutValidator } from 'ng9-rut';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  validate_page: Subject<boolean> = new Subject();
  invalid_question: Subject<boolean> = new Subject();

  validation_id_to_type = {
    "1": "NUMBER",
    "2": "CURRENCY",
    "3": "RUT",
    "4": "EMAIL",
    "5": "DATE",
    "6": "TIME",
    "7": "PERCENTAGE",
    "8": "GENERAL",
    "9": "PHONE"
  }
  validation_types = {
    // "EMAIL": Validators.email,
    "EMAIL": Validators.pattern(/(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/),
    "NUMBER": Validators.pattern('^[0-9]*$'),
    //"PHONE": Validators.pattern('^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$'),
    //"PHONE": phoneNumberValidator(),
    "PHONE": Validators.pattern(/^\+?[\d\s\-]{8,17}$/),
    "DATE": Validators.nullValidator,
    // "DATE": Validators.pattern(/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/),
    "TIME": Validators.pattern('([01]?[0-9]|2[0-3]):[0-5][0-9]'),
    "PERCENTAGE": Validators.pattern(/^100$|(^([0-9]{1,2}){1}(\.[0-9]{1,2})?$)/),
    "GENERAL": Validators.nullValidator,
    "RUT": rutValidator(),
    "CURRENCY": Validators.pattern(/^([£,$,€]?\s*\d+([\,,\.]\d+)?[£,$,€]?\s*[\-,\/,\,,\.,\+]?[\/]?\s*)$/),
    "SIGNATURE": Validators.nullValidator,
    "CODE_SMS": Validators.pattern('^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$'),
    "EQUAL_TO": equalToValidator,
    "PLACE_AUTOCOMPLETE": placeAutocompleteValidator,
    "COMBOBOX_VALUE_REQUIRED": comboboxRequiredValueValidator3
  }

  constructor(
    private trans: TranslateService, 
    private surveyService: SurveyService, 
    private networkService: NetworkService,
    private thousandsPipe: ThousandsPipe,
    private transPipe: TransPipe) { }

  /**
   * 
   * @param question question data
   * @param form form control of input
   * @returns list of validations 
   */
  getValidator(question: Question, form?: AbstractControl | FormControl): Array<ValidatorFn> {
    const vFns = [];

    if (question.title[this.surveyService.survey_data.default_language].toLocaleLowerCase() == 'teléfono' || question.title[this.surveyService.survey_data.default_language].toLocaleLowerCase() == 'phone') question.validation_type = 'PHONE';
    // required
    if (question.mandatory) vFns.push(Validators.required);
    // console.log(this.validation_types[question.validation_type]);
    // type input form validation
    if (question.validation_type != undefined) vFns.push(this.validation_types[question.validation_type]);
    if (question.max_length != undefined) vFns.push(Validators.maxLength(question.max_length));
    if (question.min_length != undefined) vFns.push(Validators.minLength(question.min_length));
    if (question.max != undefined) vFns.push(Validators.max(question.max));
    if (question.min != undefined) vFns.push(Validators.min(question.min));
    if (question.equal_to != undefined) {
      vFns.push(this.validation_types['EQUAL_TO'](question, this.surveyService));
      // needed to detect change on the other input
      this.lookForInputChanges(question, form);
    }
    if (question.type == 7 && question.mandatory) vFns.push(this.validation_types.COMBOBOX_VALUE_REQUIRED(question, this.surveyService, this.trans))
    // validation for google places input
    const trans_keys = Object.keys(question.title);
    if (trans_keys.length > 0) {
      const title = question.title[trans_keys[0]] as string;
      const is_place_autocomplete = title.startsWith("@");
      if (is_place_autocomplete) vFns.push(this.validation_types['PLACE_AUTOCOMPLETE'](question, this.surveyService, this.networkService));
    }

    return vFns;
  }
  getFileValidators(validation: string, acceptedTypes: Array<string>): Array<ValidatorFn> {
    const vFns = [];
    if (validation == 'required') vFns.push(Validators.required);
    if (validation == 'acceptedTypes') vFns.push(acceptedTypesValidator(acceptedTypes));
    return vFns;
  }
  getGroupValidator(question: Question) {
    const vFns = [];
    if (question.min_selected != null) vFns.push(minSelectValidator(question));
    if (question.max_selected != null) vFns.push(maxSelectValidator(question));
    if (question.mandatory && question.min_selected == null) {
      if (question.type == 3) vFns.push(requieredSelectMultiValidator(question));
      else if (question.type == 4) vFns.push(requieredSelectSingleValidator(question));
      else if (question.type == 100) vFns.push(requieredMatrixSelectMultiValidator(question));
    }

    return vFns;
  }
  getSensorValidator(sensor_field: SensorField, others?: any) {
    const vFns = [];
    if (sensor_field.mandatory) vFns.push(Validators.required);
    if (others != undefined) {
      if (others.max_length != undefined) vFns.push(Validators.maxLength(others.max_length));
      if (others.min_length != undefined) vFns.push(Validators.minLength(others.min_length));
    }

    vFns.push(this.validation_types[sensor_field.validation_type]);
    // console.log(vFns);
    return vFns;
  }
  /**
   * get an error message
   * @param name name of type value
   * @param error error message
   * @param extra complement to replace in error message
   * @returns 
   */
  getErrMsg(name: string, error: string, extra: string | number = '') {
    //if (error == 'REQUIRED') return this.trans.data["ERROR_REQUIRED_" + name];
    if (error == 'PATTERN') return this.transPipe.transform("ERROR.PATTERN_" + name);
    if (error == 'MAX_LENGTH') return this.transPipe.transform("ERROR.MAX_LENGTH").replace("${length}", this.thousandsPipe.transform(extra));
    if (error == 'MIN_LENGTH') return this.transPipe.transform("ERROR.MIN_LENGTH").replace("${length}", this.thousandsPipe.transform(extra));
    if (error == 'MAX') return this.transPipe.transform("ERROR.MAX").replace("${number}", this.thousandsPipe.transform(extra));
    if (error == 'MIN') return this.transPipe.transform("ERROR.MIN").replace("${number}", this.thousandsPipe.transform(extra));
    if (error == 'EQUAL_TO') return this.transPipe.transform("ERROR.EQUAL_TO").replace("${title}", extra);
    if (error == 'ERROR_PLACE_SELECTED') return this.transPipe.transform("ERROR.PLACE_SELECTED");
    if (error == 'MIN_SELECTED') {
      if (extra == 1) return this.transPipe.transform("ERROR.PLEASE_SELECT_AL_LEAST_ONE");
      else return this.transPipe.transform("ERROR.PLEASE_SELECT_AL_LEAST_MORE").replace("${amount}", extra);
    }
  }
  /**
   * 
   * @param question question data
   * @param value value for the restriction
   * @param restriction restriction attribute to add on input
   */
  setInputRestriction(question: Question, value: string, restriction: string) {
    const input = document.getElementById(question.id.toString());
    input.setAttribute(restriction, value);
  }
  /**
 * 
 * @param sensor_field sensor_field data
 * @param value value for the restriction
 * @param restriction restriction attribute to add on input
 */
  setSensorInputRestriction(sensor_field: SensorField, value: string, restriction: string) {
    const input = document.getElementById(sensor_field.label.toString());
    input.setAttribute(restriction, value);
  }
  lookForInputChanges(question: Question, form: FormControl | AbstractControl) {
    const forminput = document.getElementById(question.equal_to.toString()) as HTMLElement;
    forminput.addEventListener('input', () => {
      // console.log("change value", value);
      if (form.value != '') form.markAsDirty();
      form.updateValueAndValidity();
    })

  }
}
