import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// import { SensorFormComponent } from './components/sensor-form/sensor-form.component';
// import { NotifyOtpComponent } from './components/admin/notify-otp/notify-otp.component';
// import { SyncAnswersComponent } from './components/admin/sync-answers/sync-answers.component';

// service
import { AuthGuardService } from './services/auth-guard.service';
// import { SurveyDataResolveService } from './services/survey-data-resolve.service';

const routes: Routes = [
  {
    path: 'admin',
    //component: AdminComponent,
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'request-pin',
    //component: RequestPinComponent
    loadChildren: () => import('./pages/request-pin/request-pin.module').then(m => m.RequestPinModule),
  },
  { 
    path: '', 
    //component: SurveyComponent, 
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule),
    pathMatch: 'full' 
  },
  { 
    path: 'w/:uuid_survey/:api_domain',
    loadChildren: () => import('./pages/widget/widget.module').then(m => m.WidgetModule),
    pathMatch: 'full' 
  },
  { 
    path: 's/:uuid_survey/:api_domain',
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule),
    pathMatch: 'full' 
  },
  { 
    path: 's/:uuid_survey/:uuid_respondent/:api_domain', 
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule),
    pathMatch: 'full' 
  },
  //preview surveys
  { 
    path: 'p/:uuid_survey/:api_domain',
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule),
    pathMatch: 'full' 
  },
  { 
    path: 'p/:uuid_survey/:uuid_respondent/:api_domain', 
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule),
    pathMatch: 'full' 
  },
  { path: 'preview', redirectTo: 'preview/', pathMatch: 'full' },
  { 
    path: 'preview/:r', 
    //component: PreviewComponent 
    loadChildren: () => import('./pages/preview/preview.module').then(m => m.PreviewModule),
  },
  { 
    path: 'not-found', 
    //component: NotFoundComponent, 
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
    pathMatch: 'full' 
  },

  // { path: 'notify-otp', component: NotifyOtpComponent, pathMatch: 'full'},
  { 
    path: 'login', 
    //component: LoginComponent, 
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    pathMatch: 'full' 
  },
  { 
    path: 'thanks', 
    //component: ThanksComponent
    loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksModule),
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
