import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// env
import { environment } from 'src/environments/environment';
// encrypt
// import { DecryptPipe, EncryptPipe } from '../pipes/encrypt-decrypt.pipe';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user_data: any = null;
  u_t: string = environment.qsToken;
  logged: boolean = false;
  interval: number = 30000;

  constructor(private http: HttpClient, private router: Router
    // , private encryptPipe: EncryptPipe, private decryptPipe: DecryptPipe
  ) {
    this.getUserDataLocal();
  }
  private getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.u_t
      })
    };

    return httpOptions;
  }
  /**
   * sign in user in QServus
   * @param credentials 
   */
  login(credentials: { email: string, password: string }) {
    return this.http.post(environment.qsApiQSSUrl + "sign_in/", credentials).toPromise();
  }
  /**
   * close current session
   */
  logout() {
    this.user_data = null;
    this.logged = false;
    localStorage.removeItem("qservus-surv-user-data");
    localStorage.removeItem("logged");
    this.router.navigate(["/login"]);
  }
  /**
   * get user data
   * @param email 
   */
  getUserData(email: string) {
    return this.http.post(environment.qsApiQSSUrl + "user_data/", { "email": email }, this.getHeaders()).toPromise();
  }
  /**
   * get local user data
   */
  getUserDataLocal() {
    const d = localStorage.getItem("qservus-surv-user-data");
    if (d != undefined) {
      this.user_data = JSON.parse(d);
      this.u_t = JSON.parse(localStorage.getItem("u_t"));
    }
  }
  /**
 * store user data from login
 * @param data 
 */
  setUserData(data: any) {
    this.user_data = data;
    this.logged = true;
    localStorage.setItem("qservus-surv-user-data", JSON.stringify(this.user_data));
    localStorage.setItem("u_t", JSON.stringify(this.u_t));
    localStorage.setItem("logged", "true");
  }
  // check if any user is logged
  isLogged(): boolean {
    return localStorage.getItem("logged") == "true";
  }
  /**
   * save session PIN code for access to admin page
   * @param pin 
   */
  savePin(pin: string) {
    // this.user_data["pin"] = this.encryptPipe.transform(pin);
    this.setUserData(this.user_data);
  }
  /**
   * evaluate PIN Code
   * @param pin 
   */
  validatePin(pin: string) {
    return new Promise((res, rej) => {
      if (this.user_data.pin != undefined) {
        // const conversionDecryptOutput = this.decryptPipe.transform(this.user_data.pin);
        // res(pin == conversionDecryptOutput);
      } else res(false);
    })
  }
  setPinTimestamp() {
    const p_data = {
      until: new Date().getTime() + this.interval
    }
    localStorage.setItem("pin_validated", JSON.stringify(p_data));
  }
}
