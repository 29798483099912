/**
  * Usage: dateString | localDate:'format'
 **/
import * as moment from 'moment';
import 'moment/locale/es';

import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '../../services/translate.service';

@Pipe({
    name: 'localDate'
})
export class LocaleDatePipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(value: any, format: string, lang: string) {
        if (!value) { return ''; }
        return moment(value).locale(lang).format(format);
    }

}