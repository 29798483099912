import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Observable, pipe } from "rxjs";
import { take } from "rxjs/operators";
import { Alternative } from "../models/alternative";
import { Answer } from "../models/answer";
import { Question } from "../models/question";
import { MatrixQuestion } from "../models/question-matrix";
import { NetworkService } from "../services/network.service";
import { SurveyService } from "../services/survey.service";
import { TranslateService } from "../services/translate.service";
import { PhoneNumberUtil } from 'google-libphonenumber';
// globals
import { no_apply_option } from 'src/app/global/question-values';
/**
 * validate chilean rut
 * @returns 
 */
export function rutValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value != undefined && control.value != "") {
            return !validateRut(control.value) ? { 'invalidRut': true } : null;
        } else return null;
    };
}
/**
 * validate minimun selection for multiple selection questions
 * @param question 
 * @returns 
 */
export function minSelectValidator(question: Question): ValidatorFn {
    return (group: FormGroup): { [key: string]: boolean } | null => {
        let count = 0;
        let minimum = question.min_selected;
        let avoid_validate = false;
        question.alternatives.forEach(alt => {
            if (alt.value == no_apply_option) group.controls[no_apply_option].value ? avoid_validate = true : null;
            // else if (alt.value == '-3') group.controls["-3"].value ? avoid_validate = true : null;
            else group.controls[alt.id].value ? count++ : null;
        })
        // console.log(count < minimum ? { 'minSelected': true } : null);
        if (!avoid_validate) return count < minimum ? { 'minSelected': true } : null;
        else return null;
    };
}
/**
 * validate maximum selection for multiple selection questions
 * @param question 
 * @returns 
 */
export function maxSelectValidator(question: Question): ValidatorFn {
    return (group: FormGroup): { [key: string]: boolean } | null => {
        let count = 0;
        let maximum = question.max_selected;
        let avoid_validate = false;
        question.alternatives.map(alt => {
            if (alt.value == no_apply_option) group.controls[no_apply_option].value ? avoid_validate = true : null;
            // else if (alt.value == '-3') group.controls["-3"].value ? avoid_validate = true : null;
            else group.controls[alt.id].value ? count++ : null;
        })
        if (!avoid_validate) return count > maximum ? { 'maxSelected': true } : null;
        else return null;
    };
}
/**
 * validate for requiered multiple selection question
 * @param question 
 * @returns 
 */
export function requieredSelectMultiValidator(question: Question): ValidatorFn {
    return (group: FormGroup): { [key: string]: boolean } | null => {
        if (!question.mandatory) return null;
        else {
            let count = 0;
            question.alternatives.forEach(alt => {
                if (alt.value == 'no_apply') group.controls.no_apply.value ? count++ : null;
                else if (alt.value == 'other') group.controls.other.value ? count++ : null;
                else group.controls[alt.id].value ? count++ : null;
            })
            return count == 0 ? { 'requiredSelected': true } : null;
        }
    };
}
/**
 * validate for requiered multiple selection matrix question
 * @param question 
 * @returns 
 */
export function requieredMatrixSelectMultiValidator(question: Question): ValidatorFn {
    return (group: FormGroup): { [key: string]: boolean } | null => {
        if (!question.mandatory) return null;
        else {
            let valid = true;
            question.matrix_questions.filter((q: MatrixQuestion) => !q.hidden).forEach((q: MatrixQuestion) => {
                const alt_checked = question.alternatives.filter((a: Alternative) => group.controls[q.id].get(a.id.toString()).value)
                if (alt_checked.length == 0) {
                    valid = false;
                    return false;
                }
            })
            return !valid ? { 'requiredSelected': true } : null;
        }
    };
}
/**
 * validate for requiered single selection question
 * @param question 
 * @returns 
 */
export function requieredSelectSingleValidator(question: Question): ValidatorFn {
    return (group: FormGroup): { [key: string]: boolean } | null => {
        if (!question.mandatory) return null;
        else {

            const invalid = group.controls.value.value == "" || group.controls.value.value == null;
            return invalid ? { 'requiredSelected': true } : null;
        }
    };
}
/**
 * validate uploaded file format to file type question 
 * @param acceptedTypes 
 * @returns 
 */
export function acceptedTypesValidator(acceptedTypes: Array<string>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value != "") {
            const accepted = acceptedTypes.filter(at => at == control.value).length > 0 ? true : false;
            return !accepted ? { 'typeAccept': true } : null;
        } return null;

    }
};
/**
 * validate values between two questions
 * @param question 
 * @param surveyService 
 * @returns 
 */
export function equalToValidator(question: Question, surveyService: SurveyService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const ans_finded = surveyService.answers.find((a: Answer) => a.question_id == question.equal_to)

        if (ans_finded != undefined) {
            const result = ans_finded.answer == control.value;
            // console.log("ans_finded.answer = ", ans_finded.answer);
            // console.log("control.value = ", control.value);
            // console.log("aqualTo result = ", result);
            return !result ? { 'equalTo': true } : null;
        } return null;
    }
}

export function comboboxRequiredValueValidator(question: Question, surveyService: SurveyService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const ans_finded = surveyService.answers.find((a: Answer) => a.question_id == question.id);
        if (ans_finded != undefined) {
            // console.log("ans_finded.answer", ans_finded.answer);
            return ans_finded.answer == null ? { 'comboboxRequiredValue': true } : null;
        }
        return null;
    }
}
export function comboboxRequiredValueValidator2(question: Question, surveyService: SurveyService, valueControl: AbstractControl): AsyncValidatorFn | null {
    return (control: AbstractControl): Promise<ValidationErrors> | null => {
        return new Promise((resolve) => {
            surveyService.answers_changed.pipe(take(1)).subscribe(() => {
                // console.log("control.value", control.value);
                // console.log("valueControl.value", valueControl.value);
                resolve(valueControl.value == null ? { 'comboboxRequiredValue': true } : null);
                resolve(null);
            })

        })
    }
}
export function comboboxRequiredValueValidator3(question: Question, surveyService: SurveyService, trans: TranslateService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const alt_finded = question.alternatives.find(a => a.label[trans.actualLang] == control.value);
        // console.log("control.value", control.value);
        // console.log("alt_finded", alt_finded);
        
        return (alt_finded == undefined) ? { 'comboboxRequiredValue': true } : null;
    }
}
export function placeAutocompleteValidator(question: Question, surveyService: SurveyService, networkService: NetworkService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

        const answer = surveyService.answers.find((a: Answer) => a.question_id == question.id);
        if (control.value != "") {
            if (networkService.status) {
                if (answer != undefined) {
                    return (answer.answer.extra_data['country'] == "") ? { 'placeInvalid': true } : null;
                } return null;
            } else return null;
        } else return null;
    }
}

export function phoneNumberValidator(defaultRegion?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = control.value;
  
      try {
        const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(phoneNumber, defaultRegion);
        const isValid = phoneNumberUtil.isValidNumber(parsedPhoneNumber);
        return isValid ? null : { 'invalidPhoneNumber': true };
      } catch (e) {
        return { 'invalidPhoneNumber': true };
      }
    };
  }
// function validateRut1(rutCompleto: string): boolean {
//     if (!/^\d{1,2}(?:\.?\d{1,3}){2}-[\dkK]$/.test(rutCompleto))
//         return false;
//     if (rutCompleto.length < 9) return false;
//     let tmp = rutCompleto.split("-");
//     // tmp[0].replace(/\./g, "");

//     let digv = tmp[1];
//     const rut = tmp[0].replace(/\./g, "");;

//     if (digv == 'K') digv = 'k';
//     return (dv(rut) == digv);
// }
// function dv(T: any): any {
//     var M = 0, S = 1;
//     for (; T; T = Math.floor(T / 10))
//         S = (S + T % 10 * (9 - M++ % 6)) % 11;
//     return S ? S - 1 : 'k';
// }
function validateRut(rut: string): boolean {
    if (typeof (rut) !== 'string') return false;

    rut = rut.replace(/^0+|[^0-9kK]+/g, '').toLowerCase();

    var regEx = /^0*(\d{1,3}(\.?\d{3})*)\-?([\dkK])$/;

    if (!regEx.test(rut)) return false;
    if (rut.length < 8) return false;

    // if (rut) {
    //     parsleyInstance.$element[0].value = this.rutFormat(rut);
    // }

    var t = parseInt(rut.slice(0, -1), 10),
        m = 0,
        s = 1;

    while (t > 0) {
        s = (s + (t % 10) * (9 - m++ % 6)) % 11;
        t = Math.floor(t / 10);
    }

    var v = s > 0 ? '' + (s - 1) : 'k'
    return v === rut.slice(-1);
}
function rutFormat(rut: string) {

    var r = rut.replace(/^0+/, ""),
        a = "";

    if ("" != r && r.length > 1) {
        var t = r.replace(/\./g, "").replace(/-/g, ""),
            n = t.substring(0, t.length - 1),
            g = 0,
            l = 1;
        for (g = n.length - 1; g >= 0; g--) {
            a = n.charAt(g) + a, l % 3 == 0 && l <= n.length - 1 && (a = "." + a), l++
        }
        a = a + "-" + t.substring(t.length - 1)
    }

    return a;

}