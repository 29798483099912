export const QuestionTypeGroups = {
    1: "TEXT",
    2: "TEXT",
    3: "MULTI_SELECTION",
    4: "SINGLE_SELECTION",
    5: "SINGLE_SELECTION",
    6: "DATE",
    7: "SINGLE_SELECTION",
    8: "TEXT",
    9: "SINGLE_SELECTION",
    10: "SINGLE_SELECTION",
    11: "SINGLE_SELECTION",
    12: "SINGLE_SELECTION",
    13: "SINGLE_SELECTION",
    14: "SINGLE_SELECTION",
    15: "MULTI_SELECTION",
    16: "FILE",
    17: "SINGLE_SELECTION",
    18: "RANKING",
    19: "SINGLE_SELECTION",
    20: "SINGLE_SELECTION",
    21: "SINGLE_SELECTION",
    22: "MULTI_SELECTION",
    23: "SINGLE_SELECTION",
    24: "BOOLEAN",
    25: "MAX_DIFF",
    26: null,
    27: "SINGLE_SELECTION",
    28: null,
    29: "SINGLE_SELECTION",
    30: "FILE",
    31: "FILE",
    32: "SINGLE_SELECTION",
    33: null,
    100: "MATRIX"
}

export const MatrixScaleConvertion = {
    1: 5,
    2: 10,
    3: 14,
    4: 17,
    5: null,
    6: 9,
    7: 12,
    8: null,
    9: 4,
}