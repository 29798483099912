import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// models
import { Answer } from '../models/answer';
import { SensorField } from '../models/sensor-field';
// services
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  // network
  browser: string = "";
  public connectionStatus: string;
  public status: boolean;
  public status_sub = new BehaviorSubject<boolean>(false);

  offline_answers: Array<any> = [];

  constructor(private onlineStatusService: OnlineStatusService, private http: HttpClient) {
    this.status_sub.subscribe((value) => {
      this.status = value;
    })

    //this.pingQServusAPI();
    // this.auth.getUserDataLocal();
    this.initStatusMonitor();
  }
  // start observe network status
  initStatusMonitor() {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // Retrieve Online status Type
      // console.log("status", status);
      this.status_sub.next(status == 1);
    });

  }
  // update local answers
  updateLocalData(sensor_id: number, answers: Array<Answer>, sensor_fields: Array<SensorField>) {
    console.log('trabajar en el modo offiline para almacenar las respuestas');
    // localStorage.setItem("offline-"+ sensor_id +"-answers", JSON.stringify(data));
  }
  // return status value
  getStatus() {
    return this.status;
  }
  /**
   * get client ip
   */
  async getIP() {
    return await this.http.get('https://api.ipify.org/?format=json').toPromise().catch( (error) => {
      return {"ip": crypto.randomUUID()};
    });
  }
  
  /**
   * Send a ping to QServus to check network connection
   */
   pingQServusAPI() {
    return this.http.get(environment.qsApiQSSUrl).subscribe( (data) => {
      // console.log("pingQServusAPI data", data);
    }, error => {
      // console.log("pingQServusAPI onrejected", error);
      this.status_sub.next(error.status == 401);
    })
  }

  /**
   * Send a ping to QServus to check network connection
   */
  pingQServusAPINew(api_domain : string) {
    return this.http.get(`https://${api_domain}/api/qss/`).subscribe( (data) => {
      console.log("pingQServusAPI data", data);
    }, error => {
      // console.log("pingQServusAPI onrejected", error);
      this.status_sub.next(error.status == 401);
    })
  }
  /**
   * add a new answer to list of survey answers
   * @param hash 
   * @param answers 
   * @param sensor_form 
   */
  addAnswer(hash: string, answers: any, sensor_form?: any) {
    const obj = { hash: hash, answers: answers };
    if (sensor_form != undefined) obj['sensor_form'] = sensor_form;
    this.offline_answers.push(obj);
    // this.updateLocalData(this.offline_answers);
  }

  getImgBlob(url):Promise<any> {
    return this.http.get(url, {responseType: 'blob' as 'json'}).toPromise()
  }
}
