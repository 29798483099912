import { Injectable } from '@angular/core';
import { db } from 'src/app/db/db';
// models
import { PendingAnswers } from '../models/pending-answers';
import { TemporaryAnswers } from '../models/temporary-answers';
// services
import { QservusApiService } from './qservus-api.service';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private qservusService: QservusApiService) { }

  // PENDING ANSWERS (OFFLINE MODE)
  getPendingAnswers(su_survey_id: number) {
    return db._getByKey('pendingAnswers', 'su_survey_id', su_survey_id);
  }
  pushPendingAnswers(obj: PendingAnswers) {
    obj.status = 0;
    return db._add('pendingAnswers', obj);
  }
  updatePendingAnswers(id: number, obj: PendingAnswers) {
    return db._update('pendingAnswers', id, obj);
  }
  deletePendingAnswer(id_pending_answer: number) {
    return db._delete('pendingAnswers', id_pending_answer);
  }
  deletePendingAnswerBySurveyId(su_survey_id: number) {
    return db._deleteByKey('pendingAnswers', 'su_survey_id', su_survey_id);
  }
  // TEMPORARY ANSWERS METHODS (WHILE ANSWERING...)
  getTemporaryAnswersBySurveyUUID() {
    return db._getByKey('temporaryAnswers', 'survey_uuid', this.qservusService.uuid4);
  }
  pushTemporaryAnswer(obj: TemporaryAnswers) {
    obj.survey_uuid = this.qservusService.uuid4;
    return db._add('temporaryAnswers',  obj);
  }
  updateTemporaryAnswer(obj: TemporaryAnswers) {
    return db._update('temporaryAnswers', obj.id, obj);
  }
  deleteTemporaryAnswersBySurveyUUID() {
    return db._deleteByKey('temporaryAnswers', 'survey_uuid', this.qservusService.uuid4);
  }
}
