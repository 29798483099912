import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// services
// import { AuthService } from './auth.service';
import { RouterService } from './router.service';
// import { NetworkService } from './network.service';
import { LocalStorageService } from './local-storage.service';
import { AuthService } from './auth.service';
import { Survey } from '../models/survey';
import { promise } from 'protractor';
import { Partial_answers } from '../models/answer';

@Injectable({
  providedIn: 'root'
})
export class QservusApiService {

  apiQSSUrl: string = environment.qsApiQSSUrl;
  apiQSUrl: string = environment.qsApiUrl;
  uuid4: string = "";
  // jsonURL = "assets/test/survey_test.json";
  // jsonURL = "assets/test/survey_test_trans.json";

  // jsonURL = "https://firebasestorage.googleapis.com/v0/b/qservus-lite-qa.appspot.com/o/survey_test.json?alt=media&token=d866d263-dd94-4297-a931-ee86b57113fd";
  // jsonURL = "https://applite-qa.qservus.com/assets/test/survey_test.json";
  json_test = {
    all_questions: "assets/test/survey_test_all_type_questions.json",
    checklist_entrega_dedicada: "assets/test/checklist_entrega_dedicada.json",
    checklist_asistente_operaciones: "assets/test/checklist_asistente_operaciones.json",
    checklist_atencion_ejecutivo: "assets/test/checklist_atencion_ejecutivo.json",
    logic_to_show: "assets/test/logica_para_mostrar.json"
  }

  constructor(
    private http: HttpClient,
    private routerService: RouterService,
    private localStorageService: LocalStorageService,
    private authService: AuthService) {
  }

  // check if survey exist by URL hash
  public evaluateUUID4Param() {
    return new Promise((res, rej) => {

      this.routerService.getParams().then((params) => {
        if (params.uuid4 == undefined) this.routerService.navigate('/not-found');
        else {
          this.uuid4 = params.uuid4;
          const test = this.json_test[this.uuid4];
          if (test != undefined) res(this.getJSONTest(test));
          else this.http.get(environment.qsApiQSSUrl + "surveys/" + this.uuid4 + "/", this.getHeaders()).toPromise().then((resp) => {
            res(resp);
          }, (error) => {
            const construct_survey = this.localStorageService.get('construct-' + this.uuid4);
            if (construct_survey != undefined) res(JSON.parse(construct_survey));
            else rej(error);
          })
        }
      })

    });
  }

  /**
   * @author Matías Villar
   * @date 29-12-2022
   * @desription get survey by uuid4
   * @param survey_uuid4 uuid4 of survey 
   * @returns 
   */
  getSurvey(survey_uuid4 : string) : Promise<Survey>{
    return this.http.get<Survey>(environment.qsApiQSSUrl + "surveys/" + survey_uuid4 + "/", this.getHeaders()).toPromise()
  }

  /**
   * @author Matías Villar
   * @date 06-10-2022
   * @desription check if uuid_survey exists
   * @param uuid_survey 
   * @returns 
   */
  public async evaluateUuidSurvey(uuid_survey: string, api_domain : string, is_preview : boolean) : Promise<Survey>{
    this.uuid4 = uuid_survey;
    let result : Survey
    let test = this.json_test[this.uuid4];
    if (test != undefined) result = await this.getJSONTestNew(test)
    else
      try {
        //if is_preview call endpoint of template(pauta)
        if( is_preview ){
          result = await this.http.get<Survey>(`https://${api_domain}/api/qss/surveys-tpl/${uuid_survey}/`, this.getHeaders()).toPromise()
        }else{
          result = await this.http.get<Survey>(`https://${api_domain}/api/qss/surveys/${uuid_survey}/`, this.getHeaders()).toPromise()
        }
        
      } catch (error) {
        const construct_survey = this.localStorageService.get('construct-' + uuid_survey);
        if (construct_survey != undefined) result = JSON.parse(construct_survey)
        else return Promise.reject(error) 
      }
    return Promise.resolve(result)
  }

  /**
   * @author Matías Villar
   * @date 06-10-2022
   * @desription get Json Test
   * @param example_url 
   * @returns 
   */
  private getJSONTestNew(example_url: string) {
    return this.http.get<Survey>(example_url).toPromise()
  }
  
  private getJSONTest(example_url: string) {
    return this.http.get(example_url).toPromise();
  }
  private getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': environment.qsToken
      })
    };

    return httpOptions;
  }
  private getLoginHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.authService.u_t
      })
    };
    return httpOptions;
  }
  /**
   * get sensors from user account
   */
  getSensors() {
    return this.http.get(this.apiQSSUrl + 'sensors/', this.getHeaders()).toPromise();
  }
  /**
 * Get survey list available for user account
 */
  getSurveys() {
    return this.http.get(this.apiQSSUrl + 'surveys/', this.getLoginHeaders()).toPromise();
  }
  /**
   * Get next survey list page
   * @param url 
   * @returns 
   */
  getNextPageSurveys(url: string) {
    url = url.replace(/^http:\/\//i, 'https://');
    return this.http.get(url, this.getLoginHeaders()).toPromise();
  }
  /**
   * send token to validate on QServus
   * @token string
   */
  validateCaptcha(token: string) {
    return this.http.post(this.apiQSSUrl + 'recaptcha-validate/', { token: token }, this.getHeaders()).toPromise();
  }
  /**
   * validate if code is correct or not (must be asociated with phone number)
   * @param code validation code sended to client by sms
   * @param phone client phone number
   */
  validateCodeSMS(code: string, phone: string) {
    return this.http.post(this.apiQSSUrl + 'validate-sms-code/', { "code": code, "phone": phone }, this.getHeaders()).toPromise();
  }
  /**
   * Get access token for endpoints with bearer authentication
   * @returns 
   */
  getAccessToken(): Promise<any> {
    const credentials = {
      "email": environment.qservusGlobalAuth.user,
      "password": environment.qservusGlobalAuth.pass,
    }
    return this.http.post(environment.qsApiUrl + "token/", credentials).toPromise();
  }
  /**
   * send responses to QServus
   * @param answers list of completed surveys
   * @returns expected: list of ids of added answers
   */
  sendAnswer(answers: any): Promise<any> {
    // console.log('sensAnswer: must change URL, currently scope to prod API');
    // this.http.post(this.apiQSUrl + 'process_answers/', answers, this.getHeaders()).toPromise();
    return this.http.post(this.apiQSUrl + 'process_answers/?v=v2', answers, this.getHeaders()).toPromise();
  }
  sendPartialAnswer(answers: any, api_domain : string): Promise<any> {
    return this.http.put(`https://${api_domain}/api/qss/process-partial-answers/`, answers, this.getHeaders()).toPromise();
  }
  /**
   * check if otp code is correct
   * @param respondent_id 
   * @param otp_code 
   * @returns 
   */
  async validateOTP(respondent_uuid: string, body_request: any, otp_code?: string) {

    const oauth = await this.getAccessToken();
    // console.log(oauth);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + oauth.access
      })
    };

    let url = this.apiQSUrl + 'otp/?uuid=' + respondent_uuid;
    if (otp_code != undefined) url = this.apiQSUrl + 'otp/?uuid=' + respondent_uuid + "&otp_code=" + otp_code;

    return this.http.put(url, body_request, httpOptions).toPromise();

  }
  async manualNotificationOTP(respondent_uuid: string, email: string) {
    const oauth = await this.getAccessToken();
    // console.log(oauth);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + oauth.access
      })
    };
    return this.http.post(environment.qsApiQSSUrl + 'manual-otp-notification/', { "respondent_uuid": respondent_uuid, "email": email }, httpOptions).toPromise();

  }

  /**
   * @author Matías Villar
   * @date 06-12-2022
   * @desription Get partial answers from survey with respondent.
   * @param uuid_respondent 
   * @returns 
   */
   public getPartialAnswers(uuid_respondent: string, api_domain : string) : Promise<Partial_answers>{
    return this.http.get<Partial_answers>(`https://${api_domain}/api/qss/partial-answers/${uuid_respondent}/`, this.getHeaders()).toPromise()
  }
}
