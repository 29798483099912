import { Injectable } from '@angular/core';
import { getAuth, signInAnonymously, UserCredential } from '@angular/fire/auth';
import {
  collection,
  Firestore,
  CollectionReference,
  DocumentData,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  query,
  where,
  collectionData
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getApp } from 'firebase/app';
// models
import { AnswersData } from '../models/answers-data';
import { PreviewData } from '../models/preview-data';
import { environment } from 'src/environments/environment';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Storage } from '@angular/fire/storage';
import { getDoc, onSnapshot } from 'firebase/firestore';
import { TemporaryAnswers } from '../models/temporary-answers';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  previewCollection  : CollectionReference<DocumentData>;
  answerCollection   : CollectionReference<DocumentData>;
  temporaryAnswerCollection: CollectionReference<DocumentData>;

  constructor(private firestore: Firestore, private storage: Storage) {
    this.previewCollection = collection(this.firestore, 'preview-data'); // preview data (for OTP validation)
    this.answerCollection = collection(this.firestore, 'answer-data'); // posted answers
    this.temporaryAnswerCollection = collection(this.firestore, 'partial-answer-data'); // temporary answers (in progress survey...)

    initializeAppCheck(getApp(), {
      provider: new ReCaptchaV3Provider(environment.recaptcha.siteKey),
      isTokenAutoRefreshEnabled: true
    })
  }
  /**
   * Authenticate anonymous users
   * @return Anonymous user credentials
   */
  AnonymousAuthentication(): Promise<UserCredential> {
    const auth = getAuth();
    return signInAnonymously(auth);
  }
  /**
   * 
   * @param previewData 
   * @param collection_ref_name 
   * @returns 
   */
  createDoc(data: any, collection_ref_name: string): Promise<DocumentData> {
    return addDoc(this[collection_ref_name], data);
  }
  /**
   * Create or Update document, depends if reference exists
   * @param data 
   * @param collection_ref_name 
   */
  setDoc(data: any, path: string, segments_path: Array<string>): Promise<any> {
    return new Promise((resolve, reject) => {
      const doc_ref = doc(this.firestore, `${path}`, ...segments_path);
      setDoc(doc_ref, data, { merge: true}).then(() => {
        resolve(doc_ref.id);
      }).catch((error) => {
        resolve(error);
      });
    });

  }
  /**
   * Get data of a doc
   * @param property property name for where clausule
   * @param value property value to compare
   * @param collection_name collection name
   * @returns 
   * @date 2022-12-28
   * @author Daniel García
   */
  getDoc(property: string, value: string | number, collection_name: string): Observable<PreviewData[] | AnswersData[] | TemporaryAnswers[]> {
    const q = query(collection(this.firestore, collection_name), where(property, "==", value));
    return collectionData(q, { idField: 'id' }) as Observable<PreviewData[] | AnswersData[] | TemporaryAnswers[]>;
  }
  /**
   * Listen to a document with onSnapshot() method.
   * @param id document uid
   * @param collection_name 
   * @returns  document data
   */
  async getDocByUid(id: string, collection_name: string): Promise<DocumentData> {

    const docRef = doc(this.firestore, collection_name, ...[id]);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) return docSnap.data();
    else return null;

    // return new Observable(observer => {
    //   onSnapshot(doc(this.firestore, collection_name, ...[id]), (doc) => {
    //     observer.next(doc.data());
    //   });
    // })
  }
  /**
   * update document data of any collection
   * @param uid id document
   * @param new_data new data to set, could be anything
   * @param collection collection name/route
   * @returns firestore response
   * @date 2022-12-28
   * @author Daniel García
   */
  updateDoc(id: string, new_data: any, collection_name: string): Promise<void> {
    const doc_ref = doc(
      this.firestore,
      `${collection_name}/${id}`
    );
    return updateDoc(doc_ref, new_data);
  }
  /**
   * Call any firebase function
   * @param fn_name 
   * @param data 
   * @returns Promise<any>
   * @date 10-01-2023
   * @author Daniel García
   */
  callFunction(fn_name: string, data: any): Promise<any> {
    const exec = httpsCallable(getFunctions(), fn_name);
    return new Promise((resolve, reject) => {
      exec(data).then((result) => {
        // console.log("result", result);
        resolve(result);
      }, error => {
        // console.log("error", error);
        reject(error);
      })
    })
  }
  /**
   * 
   * @param file_name name + extension
   * @param file file to upload
   */
  uploadFile(folder: string, file_name: string, file: File): Promise<any> {
    const storageRef = ref(this.storage, folder + file_name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          // }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          reject(null);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
            // console.log('File available at', downloadURL);
            resolve({ "Location": downloadURL });
          });
        }
      );
    })

  }
  /**
   * Delete file in Storage depends on route and file name
   * @param folder 
   * @param file_name 
   */
  deleteFile(folder: string, file_name: string) {
    return new Promise((resolve, reject) => {

      try {
        const desertRef = ref(this.storage, `${folder}${file_name}`);
        // Delete the file
        deleteObject(desertRef).then(() => {
          // console.log(`file deleted: ${folder}${file_name}`);
          resolve({ status: 200, "message": `file deleted: ${folder}${file_name}` });
        }, error => resolve(null)).catch((error) => resolve(null));
      } catch (error) {
        resolve(null);
      }
    });

  }
}
