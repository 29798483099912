import { Injectable } from '@angular/core';
// environment
import { environment } from 'src/environments/environment';
// AWS
import { S3, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

@Injectable({
  providedIn: 'root'
})
export class AwsService {

  REGION: string = environment.awsBucket.region;
  S3Client: S3Client = null;
  constructor() {
    this.getCredentials();
  }
  /**
   * Authorize operations on aws with an identity pool
   */
  async getCredentials() {
    this.S3Client = new S3Client({
      region: this.REGION,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: this.REGION },
        identityPoolId: environment.awsBucket.identityPoolId
      })
    });
  }
  /**
   * opload file to bucket in AWS
   * @param file 
   */
  uploadFile(file, file_name: string = ""): Promise<any> {
    const contentType = file.type;
    const ext = file_name != "" ? file_name : file.name.split(".").pop();
    const bucket = new S3(
      {
        region: this.REGION,
        apiVersion: "2012-10-17",
        credentials: this.S3Client.config.credentials
      }
    )

    const params = {
      Key: environment.awsBucket.folder + this.makeid(15) + "." + ext,
      Bucket: environment.awsBucket.bucket_name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
      Metadata: { "origin": "survey" }
    };

    return new Promise((resolve, reject) => {

      bucket.putObject(params).then( async (data) => {
        const obj = {
          Location: environment.awsBucket.custom_domain + "/" + params.Key
        }
        resolve(obj)
      }, (error) => {
        console.log('There was an error uploading your file: ', error);
        resolve(null);
      })
    });
    //for upload progress   
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
  }
  /**
   * Generate random string depends on length parameter
   * @param length 
   * @returns 
   */
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
}
