import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
// services
import { NetworkService } from './network.service';

// locale date
import { registerLocaleData } from '@angular/common';



@Injectable()
export class TranslateService {

  public data: any = {};
  public actualLang: string;
  public actualLocale: string;
  public actualLangChange = new Subject<string>();
  public langs = ['es', 'en', 'th', 'fr', 'pt', 'it', 'zh', 'ko'];
  public global_langs = ['es', 'en', 'th', 'fr', 'pt', 'it', 'zh', 'ko'];
  public locales = {
    es: "es-mx",
    en: "en-ca",
    th: "th",
    fr: "fr",
    pt: "pt",
    it: "it",
    zh: "zh-cn",
    ko: "ko"
  }
  constructor(private http: HttpClient, private _adapter: DateAdapter<any>, private networkService: NetworkService) {
    this.actualLangChange.subscribe((value) => {
      this.actualLang = value;
      this.actualLocale = this.locales[value];
    });
  }

  /**
   * Set language
   * @param lang
   * @param uid
   */
  use(lang: string): Promise<{}> {

    return new Promise<{}>((resolve, reject) => {
      if (typeof lang === 'undefined' || this.langs.indexOf(lang) === -1) {
        lang = 'es';
      }

      this.networkService.status_sub.subscribe((value) => {
        
        // console.log(" translateService use() network status", value);

        if (this.networkService.status) {
          
          const langPath = `assets/i18n/${lang}.json`;
          this.http.get<{}>(langPath).subscribe(
            translation => {
              this.data = Object.assign({}, translation || {});

              localStorage.setItem('lang', lang);
              localStorage.setItem('actual-translation', JSON.stringify(this.data));
              this.actualLangChange.next(lang);
              resolve(this.data);
            },
            error => {
              this.data = {};
              localStorage.setItem('lang', lang);
              this.actualLangChange.next(lang);
              resolve(this.data);
            }
          );
        } else {
          const trans_data_str = localStorage.getItem('actual-translation');
          this.actualLangChange.next(lang);

          this.data = (trans_data_str != undefined) ? JSON.parse(trans_data_str) : null;
          // console.log("this.data", this.data);

          resolve(this.data);
        }
      })


    });
  }

  getBrowserLang() {
    const lang = window.navigator.language;
    return lang.slice(0, 2);
  }
  updateLangs(langs: Array<string>) {
    this.langs = langs;
  }
  resetLangs() {
    this.langs = this.global_langs;
  }
}
