import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
// routing
import { AppRoutingModule } from './app-routing.module';
// env
import { environment } from '../environments/environment';
// recaptcha v3
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
// code pin input
// import { CodeInputModule } from 'angular-code-input';
// material
import { AppMaterialModule } from './app.material.module';
// pipes
// import { DecryptPipe, EncryptPipe } from './pipes/encrypt-decrypt.pipe';
import { TransPipe } from './shared/pipes/trans.pipe';
import { ThousandsPipe } from './shared/pipes/thousands.pipe';
import { LocaleDatePipe } from './shared/pipes/locale-date.pipe';
// service
import { TranslateService } from './services/translate.service';
import { ResponsiveService } from './services/responsive.service';
import { UpdateWorkerService } from './services/update-worker.service';
import { SensorFormService } from './services/sensor-form.service';
// component
import { AppComponent } from './app.component';
// admin
// import { SyncAnswersComponent } from './components/admin/sync-answers/sync-answers.component';
// import { WidgetAdminComponent } from './shared/components/widget-admin/widget-admin.component';
// import { BannerNoPinComponent } from './components/admin/banner-no-pin/banner-no-pin.component';
// rut validators, pipe and formatter
import { Ng9RutModule } from 'ng9-rut';
// format date
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule } from '@angular/material/core';
import { MomentUtcDateAdapter, MY_FORMATS } from './adapters/date.adapter';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
// sentry
import * as Sentry from "@sentry/angular";
// directives
import { LazyImageDirective } from './directives/lazy-image.directive';
// recaptcha v3
import { RecaptchaV3Module, RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
// network
import { OnlineStatusModule } from 'ngx-online-status';
// google places
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
// firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app'
import { provideFirestore, getFirestore } from '@angular/fire/firestore'
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions'
import { provideStorage, getStorage } from '@angular/fire/storage'
// init translate service
export function setupTranslateFactory(service: TranslateService): Function {
  let lang = localStorage.getItem('lang');
  if (lang == null) {
    lang = service.getBrowserLang();  // default lang
  }
  return () => service.use(lang);
}


@NgModule({
  declarations: [
    AppComponent,
    LazyImageDirective,
  ],
  imports: [
    //NgBytesPipeModule,
    //CodeInputModule,
    //FlexLayoutModule,
    //CodeInputModule,
    //RecaptchaFormsModule, // if you need forms support
    BrowserModule,
    CommonModule,
    
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' }),
    // AngularFireModule.initializeApp(environment.firebaseConfig, environment.production ? 'qservus-survey-prod' : 'qservus-survey-qa'),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      const firestore = getFirestore();
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      // connectFunctionsEmulator(functions, "localhost", 5001);
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      return storage;
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    Ng9RutModule,
    MatMomentDateModule,
    NativeDateModule,
    RecaptchaV3Module,
    OnlineStatusModule,
    GooglePlaceModule
  ],
  providers: [
    TranslateService,
    TransPipe,
    LocaleDatePipe,
    UpdateWorkerService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    ResponsiveService,
    SensorFormService,
    ThousandsPipe,
    // EncryptPipe, 
    // DecryptPipe,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (translate: TranslateService) => {
        return translate.actualLang
      },
      deps: [TranslateService]
    },
    // {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]},
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      })
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
