import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
// models
import { QuestionPage } from '../models/question_page';
import { SensorField } from '../models/sensor-field';
import { Survey } from '../models/survey';
import { Question } from '../models/question';
import { Answer, Partial_answers } from '../models/answer';
import { Derivation } from '../models/derivation';
import { PreviewData } from '../models/preview-data';
import { ExtraData } from '../models/extra-data';
import { Alternative } from '../models/alternative';
import { AnswerCondition, ExtraDataCondition, LogicToShow } from '../models/logic-to-show';
// components
import { ModalConfirmComponent } from '../shared/components/modal-confirm/modal-confirm.component';
import { ModalProcessPendingAnswersComponent } from '../shared/components/modal-process-pending-answers/modal-process-pending-answers.component';
// globals
import { MatrixScaleConvertion, QuestionTypeGroups } from '../global/question-types';
// services
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from './translate.service';
import { RouterService } from './router.service';
import { AwsService } from './aws.service';
import { NetworkService } from './network.service';
import { LoadingScreenService } from './loading-screen.service';
import { DbService } from './db.service';
import { SnackbarService } from './snackbar.service';
import { FirebaseService } from './firebase.service';
// moment
import * as moment from 'moment';
import { empty, isArray } from '../utils/utils';
// pipes
import { TransPipe } from '../shared/pipes/trans.pipe';
import { SessionStorageService } from './session-storage.service';
import { QservusApiService } from './qservus-api.service';
import { Translate } from '../models/translate';
import { DocumentData } from 'firebase/firestore';
import { Subscription } from 'dexie';
// env
// import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  public survey_data: Survey = null;
  public surveyLoaded: boolean = false;
  public answers: Array<Answer> = [];
  answers_changed: BehaviorSubject<boolean> = new BehaviorSubject(false);
  answers_change_status: boolean = false; // allow send partial answers

  public reset_progress: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public current_page: QuestionPage = null;
  public current_page_index: number = 0;

  public show_sensor_form: boolean = false;

  public derivations: Array<Derivation> = [];

  public m_answers: number = 0;
  public total_for_progress: number = 0;

  public langs: Array<string> = [];

  public pending_answers: Array<any> = [];
  public call_check_progress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public last_question_edited: number = null;

  public ip: string = "";

  public is_preview: boolean = false
  public uuid_respondent : string = ""
  public api_domain : string = ""

  public qn!      : number
  public a!       : string
  public type!    : number
  public channel! : string

  temp_answer: DocumentData;
  temp_answer_sub: Subscription;
  partial_answers!: Partial_answers;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private trans: TranslateService,
    private networkService: NetworkService,
    private dialog: MatDialog,
    private routerService: RouterService,
    private awsService: AwsService,
    private loadingScreenService: LoadingScreenService,
    private dbService: DbService,
    private snack: SnackbarService,
    private transPipe: TransPipe,
    private route: ActivatedRoute,
    private qservusApiService: QservusApiService,
    private firebaseService: FirebaseService) {
    this.getIP();
    this.answers_changed.subscribe((next) => {
      if (next) {
        this.answers_change_status = true;
        this.answers_changed.next(false);
      }

    })
  }
  async getIP() {
    if (!this.localStorageService.get("sip")) {
      const r = await this.networkService.getIP();
      this.ip = r["ip"];
      this.localStorageService.set("sip", this.ip);
    } else this.ip = this.localStorageService.get("sip");
  }
  /**
   * get question object by id
   * @param question_id 
   * @returns 
   */
  getQuestionById(question_id) {
    let question = null;
    this.survey_data.pages.every((p) => {
      question = p.questions.find(q => q.id == question_id);
      return question == undefined
    })
    return question;
  }
  /**
 * get question object by order
 * @param order 
 * @returns 
 */
  getQuestionByQN(q_n: number) {
    let question = null;
    this.survey_data.pages.every((p) => {
      question = p.questions.find((q: Question) => q.q_n == q_n);
      // console.log("getQuestionByQN question finded ", question);
      return question == undefined
    })
    return question;
  }
  /**
   * check if exist partial answers for this survey
   */
  checkLocalAnswers() {
    return new Promise(async (resolve) => {

      const temporaryAnswers = await this.dbService.getTemporaryAnswersBySurveyUUID();

      if (temporaryAnswers.length) {

        this.answers = temporaryAnswers[0].answers;

        this.answers.sort((a, b) => a.page - b.page);

        // console.log("checkLocalAnswers | local answers of incomplete survey =", this.answers);

        const page_n_order = await this.getIncompletePageNOrder();

        // check derivations
        const deriv_stringify = this.localStorageService.get("temp-derivations-" + this.survey_data.id.toString());
        if (deriv_stringify != undefined) this.derivations = JSON.parse(deriv_stringify);

        if (page_n_order != this.current_page.n_order) {

          const idx = this.survey_data.pages.findIndex(p => p.n_order == page_n_order);

          const page_to_load = this.survey_data.pages[idx];

          const has_visible_questions = this.hasVisibleQuestions(page_to_load);

          if (has_visible_questions) {
            this.current_page = page_to_load
            this.current_page_index = idx;
          } else {
            this.current_page = this.survey_data.pages[0];
            this.current_page_index = 0;
          }

        }

        // check logic to show for all questions
        this.checkPageLogicToShow(false);

        this.call_check_progress.next(true);
        this.showModalPendingAnswer()

        resolve(true);
      }else{
        //always check if survey has query params..
        await this.checkQueryParams()
        // not temporary answers answers saved locally so we need to verify if exists partials answers
        // first validate if survey has respondent.
        if( this.uuid_respondent != ""){
          await this.getPartialAnswers().then( (has_partial_answers) =>{
            if(has_partial_answers){
              this.showModalPendingAnswer()
            }
            resolve(true);
          })
        }
        resolve(true);
      }
    })
  }
  /**
 * update answer list from SurveyService
 * @param page current page id
 * @param question current question answered
 * @param response response data
 * @param valid: question validation status
 * @author Daniel García Silva
 * @date 2022-11-30
 */
  saveTemporaryAnswer(page: number, question: Question, response: any, valid: boolean) {

    this.last_question_edited = question.id;
    let finded_answer = this.answers.find(a => a.question_id == question.id);

    if (!finded_answer) {
      // general
      this.answers.push({ page: page, question_id: question.id, type: question.type, answer: response.answer, valid: valid });
      finded_answer = this.answers.find((a: Answer) => a.question_id == question.id);
      // additional info
      if (response.other_text != undefined) finded_answer.other_text = response.other_text;
      if (response.alternative_id != undefined) finded_answer.alternative_id = response.alternative_id;
      if (response.motive != undefined) finded_answer.motive = response.motive;
      if (question.matrix_type != undefined) finded_answer.matrix_type = question.matrix_type;

    } else {
      // general
      finded_answer.answer = response.answer;
      finded_answer.valid = valid;
      // aditional info
      if (response.other_text != undefined) finded_answer.other_text = response.other_text;
      if (response.alternative_id != undefined) finded_answer.alternative_id = response.alternative_id;
      if (response.motive != undefined) finded_answer.motive = response.motive;
      if (question.matrix_type != undefined) finded_answer.matrix_type = question.matrix_type;
    }

    if (response != null) this.saveDerivation(question.id, this.current_page.id, response.answer != undefined ? response.answer.next_page_id : null);
    // console.log("new response = ", this.answers);
    this.checkPageLogicToShow(true);
    this.saveAnswersInFirebase();
    this.answers_changed.next(true);
    this.call_check_progress.next(true);
  }
  /**
   * Save temporary answers in indexedDB
   * @author Daniel García Silva
   * @date 2022-11-30
   */
  async saveTemporaryAnswerInIndexedDB() {
    const tempAnswerDB = await this.dbService.getTemporaryAnswersBySurveyUUID();
    const ans = this.answers.map(object => ({ ...object }));
    ans.filter((a: Answer) => a.type == 1 || a.type == 6)
      .forEach((a: Answer) => {

        const q = this.survey_data.pages.map((p: QuestionPage) => {
          return p.questions.find((q: Question) => q.id == a.question_id)
        })[0]
        
        if (q?.type == 6 || (q?.type == 1 && q?.validation_type == "5")) {
          if (moment.isMoment(a.answer)) a.answer = a.answer.format("DD/MM/YYYY");
          else {
            a.answer = moment(a.answer);
            a.answer = a.answer.format("DD/MM/YYYY");
          }
        }

      })

    if (tempAnswerDB.length) {
      tempAnswerDB[0].answers = ans;
      this.dbService.updateTemporaryAnswer(tempAnswerDB[0]);
    } else {
      this.dbService.pushTemporaryAnswer({
        answers: ans,
        survey_uuid: ""
      });
    }
  }
  /**
   * save answers in Firebase to process as partial answers after visibilitychange browser event
   */
  async saveAnswersInFirebase() {

    if (this.networkService.getStatus()) { // online
      const has_files = await this.hasFileQuestions(this.answers);
      if (has_files) {

        this.firebaseService.AnonymousAuthentication().then(() => {
          this.uploadFilesToFirebase().then(async (res) => {
            const formated_answer = await this.formatProcessAnswer();
				    formated_answer["update_at"] = new Date().getTime();
				    formated_answer["domain_api"] = this.api_domain;
            const doc_id = await this.firebaseService.setDoc(formated_answer, "partial-answer-data/", [`${this.uuid_respondent ? this.uuid_respondent : this.ip}__${this.survey_data.id_survey.toString()}`]);
            this.temp_answer = formated_answer;
            // this.firebaseService.getDocByUid(doc_id, "partial-answer-data/").then((doc) => this.temp_answer = doc);
            this.answers_change_status = true;

          })
        })

      } else {
        this.firebaseService.AnonymousAuthentication().then(async () => {

          const formated_answer = await this.formatProcessAnswer();
          formated_answer["update_at"] = new Date().getTime();
          formated_answer["domain_api"] = this.api_domain;
          const doc_id = await this.firebaseService.setDoc(formated_answer, "partial-answer-data/", [`${this.uuid_respondent ? this.uuid_respondent : this.ip}__${this.survey_data.id_survey.toString()}`]);
          this.temp_answer = formated_answer;
            // this.firebaseService.getDocByUid(doc_id, "partial-answer-data/").then((doc) => this.temp_answer = doc);
          this.answers_change_status = true;
        })
      }
    }

  }
  uploadFilesToFirebase() {
    return new Promise(async (resolve, reject) => {

      const filter_answers = this.answers.filter((a: Answer) => {
        if (a.answer != null) return a.answer['file'] != undefined && a.answer["new_file"];
        else return false;
      });

      for (const [i, a] of filter_answers.entries()) {
        const question = this.getQuestionById(a.question_id);
        if (a.answer?.file_prev_ext != null) {
          const del = await this.firebaseService.deleteFile(`partial-answers/${this.survey_data.id_survey}/${this.uuid_respondent ? this.uuid_respondent : this.ip}/`, `${question.id}${a.answer.file_prev_ext}`);
          // console.log(del);
        }
        const r = await this.firebaseService.uploadFile(`partial-answers/${this.survey_data.id_survey}/${this.uuid_respondent ? this.uuid_respondent : this.ip}/`, `${question.id}${a.answer.file_ext}`, a.answer.file) as any;

        const real_ans_idx = this.answers.findIndex(ans => ans.question_id == a.question_id);
        if (r.Location != undefined) this.answers[real_ans_idx].answer["url"] = r.Location;
        if (r == null) resolve(false);
        if (i == filter_answers.length - 1) resolve(true);
      }
    })
  }
  /**
 * 	check if the page to display has visible questions 
 * @param page 
 * @returns 
 */
  hasVisibleQuestions(page: QuestionPage) {
    let has_visibility = false;
    page.questions.every((q: Question) => {
      // console.log("q.visibility", q.visibility);
      if (q.visibility) {
        has_visibility = true;
        return false;
      } else return true;
    })
    return has_visibility;
  }
  checkPageLogicToShow(enable_answers_removal: boolean = false) {
    this.survey_data.pages.forEach((page: QuestionPage) => {
      page.questions.forEach((q: Question) => {
        if (q.logic_to_show?.operator != undefined || q.logic_to_show?.operator != undefined) this.checkLogicToShow(q, q.logic_to_show);
        if (q.visibility) {
          q.alternatives?.forEach((a: Alternative) => {
            if (a.logic_to_show != null) this.checkAlternativeLogicToShow(q, a, a.logic_to_show, enable_answers_removal);
          })
        }
      });
    });
  }
  /**
 * check if conditions of logic to show are fullfilled for questions
 * @param question question to evaluate
 * @param logic logic to show data
 * @param direction way on page change
 */
  checkLogicToShow(question: Question, logic: LogicToShow) {
    // console.log(question);

    if (question.initial_visibility != undefined) question.initial_visibility = question.visibility;

    const total_conditions = logic.conditions.length;
    let conditions_fullfiled = 0;

    logic.conditions.forEach((condition: any) => {
      // console.log("condition", condition);

      if (condition['question_id'] != undefined) { // question condition
        const ans_condition = condition as AnswerCondition;
        // console.log("ans_condition", ans_condition);

        // find answer
        const saved_answer = this.answers.find((a: Answer) => a.question_id == ans_condition.question_id);

        if (saved_answer != undefined) {
          // console.log("ans_condition.answer", ans_condition.answer);

          // check type of answer // boolean | Array<number>
          if (typeof ans_condition.answer == "boolean") { // boolean answer
            // console.log("if boolean | saved_answer =", saved_answer);
            ans_condition.answer == saved_answer.answer ? conditions_fullfiled++ : null;

          } else if (Array.isArray(ans_condition.answer)) { // array answer

            const array_answers = ans_condition.answer as Array<string | number>;
            // console.log("condition array_answers", array_answers);


            // saved answers array
            if (Array.isArray(saved_answer.answer)) {
              // console.log("saved is array");
              for (let ans of saved_answer.answer) {
                // saved_answer.answer.forEach((a: Array<any>) => {
                if (ans != undefined) {
                  if (array_answers.includes(ans)) conditions_fullfiled++;
                }
              }

            } else if (typeof saved_answer.answer == "number" || typeof saved_answer.answer == "string") {

              if (array_answers.includes(saved_answer.answer.toString())) conditions_fullfiled++
            }
            // saved answer number

            // console.log("array_anwers =", array_answers);
            // })

          } else { // string answer
            if (saved_answer.answer?.value != undefined) {
              if (saved_answer.answer.value == ans_condition.answer) conditions_fullfiled++;
            } else if (saved_answer.answer == ans_condition.answer) conditions_fullfiled++;

          }
        }

      } else { // extra data condition
        const extra_data_condition = condition as ExtraDataCondition;

        const survey_extra_data = this.survey_data.extra_data;

        // check if extra_data exist (and must exist if it is a condition of this type...)
        if (survey_extra_data != undefined) {

          this.survey_data.extra_data.every((extra: ExtraData) => {

            if (extra_data_condition.extra_data == extra.property) {
              if (extra_data_condition.value == extra.value) {
                conditions_fullfiled++;
                return false;
              }
            }
            return true;
          })
        }
      }
    });
    // evaluate result
    // console.log("total_conditions =", total_conditions);
    // console.log("conditions_fullfiled =", conditions_fullfiled);

    if (logic.operator == 1) {
      if (conditions_fullfiled > 0) question.visibility = true;
      else question.visibility = question.initial_visibility;
    } else {
      if (conditions_fullfiled == total_conditions) question.visibility = true;
      else question.visibility = question.initial_visibility;
    }
    if (question.mandatory) {
      this.call_check_progress.next(true);
    }
  }
  /**
  * check if conditions of logic to show are fullfilled for alternatives
   * @param question 
   * @param Alternative 
   * @param logic 
   */
  checkAlternativeLogicToShow(question: Question, alternative: Alternative, logic: LogicToShow, remove: boolean = false) {

    alternative.visibility = false;

    const total_conditions = logic.conditions.length;
    let conditions_fullfiled = 0;

    logic.conditions.forEach((condition: any) => {
      // console.log("condition", condition);

      if (condition?.question_id != undefined) { // question condition
        const ans_condition = condition as AnswerCondition;
        // console.log("ans_condition", ans_condition);

        // find answer
        const saved_answer = this.answers.find((a: Answer) => a.question_id == ans_condition.question_id);

        if (saved_answer != undefined) {
          // console.log("ans_condition.answer", ans_condition.answer);

          // check type of answer // boolean | Array<number>
          if (typeof ans_condition.answer == "boolean") { // boolean answer
            // console.log("if boolean | saved_answer =", saved_answer);
            ans_condition.answer == saved_answer.answer ? conditions_fullfiled++ : null;

          } else if (Array.isArray(ans_condition.answer)) { // array answer

            const array_answers = ans_condition.answer as Array<string | number>;
            // console.log("condition array_answers", array_answers);
            // saved answers array
            if (Array.isArray(saved_answer.answer)) {
              // console.log("saved is array");
              for (let ans of saved_answer.answer) {
                // saved_answer.answer.forEach((a: Array<any>) => {
                if (ans != undefined) {
                  if (array_answers.includes(ans)) conditions_fullfiled++;
                }
              }

            } else if (typeof saved_answer.answer == "number" || typeof saved_answer.answer == "string") {
              if (array_answers.includes(saved_answer.answer.toString())) conditions_fullfiled++
            }

          } else { // string answer
            if (saved_answer.answer?.value != undefined) {
              if (saved_answer.answer.value == ans_condition.answer) conditions_fullfiled++;
            } else if (saved_answer.answer == ans_condition.answer) conditions_fullfiled++;

          }
        }

      } else { // extra data condition
        const extra_data_condition = condition as ExtraDataCondition;

        const survey_extra_data = this.survey_data.extra_data;

        // check if extra_data exist (and must exist if it is a condition of this type...)
        if (survey_extra_data != undefined) {

          this.survey_data.extra_data.every((extra: ExtraData) => {

            if (extra_data_condition.extra_data == extra.property) {
              if (extra_data_condition.value == extra.value) {
                conditions_fullfiled++;
                return false;
              }
            }
            return true;
          })
        }
      }
    });
    // evaluate result
    if (logic.operator == 1) {
      if (conditions_fullfiled > 0) alternative.visibility = true;
      else {

        const idx = this.answers.findIndex((a: Answer) => a.question_id == question.id);
        if (idx != -1) {
          // check type answer
          const ans = this.answers[idx];

          if (ans.alternative_id != undefined) { // scale numeric
            if (ans.alternative_id == alternative.id) {
              this.answers.splice(idx, 1);
              this.call_check_progress.next(true);
            }
          } else if (ans.answer?.length > 0) { // select multi (alternative array)
            const idx_alt = ans.answer.find((a: Alternative) => a.id == alternative.id);
            if (idx_alt != -1) {
              ans.answer.splice(idx_alt, 1);
              this.call_check_progress.next(true);
            }
          } else if (ans.answer?.id != undefined) { // select single (alternative object)
            if (ans.answer.id == alternative.id) {
              this.answers.splice(idx, 1);
              this.call_check_progress.next(true);
            }
          }
        }
      }

    } else {
      if (conditions_fullfiled == total_conditions) alternative.visibility = true;
      else {
        // console.log("not fullfiled");

        const idx = this.answers.findIndex((a: Answer) => a.question_id == question.id);
        if (idx != -1 && remove) {
          // check type answer
          const ans = this.answers[idx];
          if (ans.alternative_id != undefined) { // scale numeric
            // console.log("scale numeric answer");
            if (ans.alternative_id == alternative.id) {
              this.answers.splice(idx, 1);
              this.call_check_progress.next(true);
            }
          } else if (ans.answer?.length > 0) { // select multi (alternative array)
            // console.log("select multi answer");
            const idx_alt = ans.answer.find((a: Alternative) => a.id == alternative.id);
            if (idx_alt != -1) {
              ans.answer.splice(idx_alt, 1);
              this.call_check_progress.next(true);
            }
          } else if (ans.answer?.id != undefined) { // select single (alternative object)
            // console.log("select single answer");
            if (ans.answer.id == alternative.id) {
              this.answers.splice(idx, 1);
              this.call_check_progress.next(true);
            }
          }
        }
      }
    }
  }
  /** format responses to register data in QServus */
  async formatProcessAnswer() {
    let survey = {
      "su_survey_id": this.survey_data.id_survey,
      "ip": this.ip,
      "user_agent": window.navigator.userAgent,
      "answers": [],
      "extra_data": [],
      "respondent_data": {},
      "status": 0
    }
    if (this.survey_data.type == "campaña") {
      // survey["qs_campaign_id"] = this.survey_data.id;
      survey["uuid_respondent"] = this.uuid_respondent;

      if (this.survey_data.title["es"].includes("Entrega Dedicada")) this.configureCustomRespondentData(survey);

    } else if (this.survey_data.type == "sensor") {
      survey["respondent_data"] = {
        name: "",
        email: "",
        phone: ""
      }
      // survey["qs_sensor_id"] = this.survey_data.id;
      // add sensor fields
      const sensor_fields = this.localStorageService.get('form-fields-' + this.survey_data.id);
      if (sensor_fields != undefined) {
        const sensor_field_json = JSON.parse(sensor_fields);

        if (sensor_field_json != undefined) {
          sensor_field_json
            .filter((sf: SensorField) => sf.label == 'EMAIL' || sf.label == 'PHONE' || sf.label == "NAME")
            .forEach((sf: SensorField) => {
              survey["respondent_data"][sf.label.toString().toLowerCase()] = sf.answer != undefined ? sf.answer : "";
            })
          const extra_data = sensor_field_json.filter((sf: SensorField) => sf.label != 'EMAIL' && sf.label != 'PHONE' && sf.label != "NAME");

          extra_data.forEach(sf => {
            survey.extra_data.push({
              "label": sf.label,
              "value": sf.answer
            })
          })
        }

      } else this.configureCustomRespondentData(survey);

    } else if (this.survey_data.type == "checklist") {

      // survey["qs_checklist_id"] = this.survey_data.id;
      // add sensor fields
      const sensor_fields = this.localStorageService.get('form-fields-' + this.survey_data.id);
      if (sensor_fields != undefined) {
        const sensor_field_json = JSON.parse(sensor_fields);

        if (sensor_field_json != undefined) {
          sensor_field_json
            .filter((sf: SensorField) => sf.label == 'EMAIL' || sf.label == 'PHONE' || sf.label == "NAME")
            .forEach((sf: SensorField) => {
              survey["respondent_data"][sf.label.toString().toLowerCase()] = sf.answer != undefined ? sf.answer : "";
            })
          const extra_data = sensor_field_json.filter((sf: SensorField) => sf.label != 'EMAIL' && sf.label != 'PHONE' && sf.label != "NAME");

          extra_data.forEach(sf => {
            survey.extra_data.push({
              "label": sf.label,
              "value": sf.answer
            })
          })
        }

      } else this.configureCustomRespondentData(survey);
    }

    if (this.survey_data.title["es"].includes("Entrega Dedicada")) {
      survey.extra_data.push({
        "label": "tipo_entrega",
        "value": this.survey_data.title["es"].includes("nuevo") ? "nuevo" : "usado"
      })
      const sales_number = this.survey_data.pages[0].questions.find((q) => q.title['es'].includes('nota de venta'));
      if (sales_number != undefined) {
        const question_sales_number_id = sales_number.id;
        survey.extra_data.push({
          "label": "numero_nota_venta",
          "value": this.answers.find(a => a.question_id == question_sales_number_id).answer
        })
      }
      const customer_dni = this.survey_data.pages[0].questions.find((q) => q.title['es'].includes('Rut') || q.title['es'].includes('DNI'));
      if (customer_dni != undefined) {
        const question_customer_dni_id = customer_dni.id;
        survey.extra_data.push({
          "label": "identificador_cliente",
          "value": this.answers.find(a => a.question_id == question_customer_dni_id).answer
        })
      }
    }

    this.answers.sort((a, b) => a.question_id - b.question_id);
    this.answers.forEach((answer: Answer, i: number) => {

      let question = null;
      if (answer.type == 1) question = this.getQuestionById(answer.question_id);
      // console.log("answer " + ++i + " =", answer);
      let ans_to_add = {
        "su_question_id": answer.question_id,
        "type": answer.type,
        "answer": null
      }
      // format answer
      if (question == null) this.formatAnswerDependOnQuestionType(answer, ans_to_add, survey);
      else this.formatAnswerDependOnQuestionType(answer, ans_to_add, survey, question.validation_type != null ? question.validation_type : null);

      survey.answers.push(ans_to_add);
    })
    // filter matrix questions
    survey.answers = survey.answers.filter((a: Answer) => a.type != 100).filter((a: Answer) => a.answer != null);

    // logic to remove answers with id < 0 and add it as extra_data
    const extra_questions = survey.answers.filter((a) => a.su_question_id == -9 || a.su_question_id == -10 || a.su_question_id == -11 || a.su_question_id == -12);

    if (extra_questions.length > 0) {
      // looping through answer list
      extra_questions.forEach(((a) => {
        let property_name = "";
        if (a.su_question_id == -9) property_name = "Sucursal";
        else if (a.su_question_id == -10) property_name = "Marca";
        else if (a.su_question_id == -12) property_name = "Modelo";
        else if (a.su_question_id == -11) property_name = "notification_channel";

        survey.extra_data.push({
          "label": property_name,
          "value": a.answer
        })

      }))
    }

    return survey;
  }
  /**
   * get the answer in the format needed to register it in QServus
   * @param saved_answer 
   * @param ans_to_add 
   */
  formatAnswerDependOnQuestionType(saved_answer: Answer, ans_to_add: any, survey: any, validation_type?: number) {
    const type = this.getQuestionGroup(saved_answer.type);
    if (type == "TEXT") {
      ans_to_add.answer = saved_answer.answer;
      if (validation_type != undefined && validation_type != null) {
        if (validation_type == 5) {
          if (moment.isMoment(saved_answer.answer)) ans_to_add.answer = saved_answer.answer.format("DD/MM/YYYY");
          else {
            saved_answer.answer = moment(saved_answer.answer);
            ans_to_add.answer = saved_answer.answer.format("DD/MM/YYYY");
          }
        }
      }
      // if (moment.isMoment(saved_answer.answer)) ans_to_add.answer = saved_answer.answer.toISOString();
      if (saved_answer.answer.extra_data != undefined) {
        ans_to_add.answer = saved_answer.answer.text;
        Object.keys(saved_answer.answer.extra_data).forEach((key) => {
          // console.log(survey);

          survey.extra_data.push({
            "label": key,
            "value": saved_answer.answer.extra_data[key]
          })
        })
      }
    }
    else if (type == "DATE") {
      // if (moment.isMoment(saved_answer.answer)) ans_to_add.answer = saved_answer.answer.toISOString();
      if (moment.isMoment(saved_answer.answer)) ans_to_add.answer = saved_answer.answer.format("DD/MM/YYYY");
      else {
        saved_answer.answer = moment(saved_answer.answer);
        ans_to_add.answer = saved_answer.answer.format("DD/MM/YYYY");
      }
    }
    else if (type == "SINGLE_SELECTION") {
      if (typeof saved_answer.answer == "string" || typeof saved_answer.answer == "number") ans_to_add.answer = saved_answer.answer;
      else ans_to_add.answer = saved_answer.answer.value.toString();
      // add motive
      if (saved_answer.motive != undefined) if (saved_answer.motive != "") ans_to_add["answer_motive"] = saved_answer.motive;
      // add other option
      if (saved_answer.other_text != undefined) if (saved_answer.other_text != "") ans_to_add["answer_other"] = saved_answer.other_text;
    }
    else if (type == "MULTI_SELECTION") {
      const r = saved_answer.answer.map((a: Alternative) => { return a.value }) as Array<any>;
      if (r != undefined) ans_to_add.answer = r.toString();
      // add other option
      if (saved_answer.other_text != undefined) if (saved_answer.other_text != "") ans_to_add["answer_other"] = saved_answer.other_text;
    }
    else if (type == "BOOLEAN") ans_to_add.answer = saved_answer.answer;
    else if (type == "RANKING") {
      if (saved_answer.answer != null) {
        ans_to_add.answer = saved_answer.answer.map((a, i) => {
          return { 'order': ++i, 'alternative': a.id }
        })
      }
    }
    else if (type == "FILE") {
      if (this.networkService.getStatus()) {
        if (saved_answer.answer != null) {
          ans_to_add.answer = saved_answer.answer.url;
        }
      } else ans_to_add.answer = saved_answer.answer;
    }
    else if (type == "MATRIX") {
      // console.log("matrix answers", saved_answer.answer);
      // sacar id de tipo de escala
      saved_answer.answer.forEach(ma => { // loop in matrix answers
        // console.log("saved_answer.matrix_type", saved_answer.matrix_type);
        let ma_answer = null;
        let matrix_questions_type = MatrixScaleConvertion[saved_answer.matrix_type];

        if (ma.alternative != undefined) if (ma.alternative != null) {
          if (!isArray(ma.alternative)) {
            ma_answer = ma.alternative.value.toString();
          } else { // selection multi
            const r = ma.alternative.map((a: Alternative) => { return a.value }) as Array<any>;
            ma_answer = r.toString();
            matrix_questions_type = 3;
          }
        }

        survey.answers.push({
          "su_question_id": ma.question.id,
          "type": matrix_questions_type,
          "answer": ma_answer
        });

      });
    }
    else if (type == "MAX_DIFF") ans_to_add.answer = saved_answer.answer;
  }
  /**
   * Add properties requiered to save answers on "Entrega Dedicada" campaigns.
   * @param survey 
   */
  configureCustomRespondentData(survey: any) {
    // get name
    let question_name_id, question_email_id, question_phone_id = null;

    const q_name = this.survey_data.pages[0].questions.find((q) => q.title['es'] == 'Nombre');
    const q_email = this.survey_data.pages[0].questions.find((q) => q.title['es'] == 'Correo');
    const q_phone = this.survey_data.pages[0].questions.find((q) => q.title['es'] == 'Teléfono');
    if (q_name != undefined) {
      question_name_id = q_name.id;
      survey.respondent_data.name = this.answers.find(a => a.question_id == question_name_id).answer;
    }
    if (q_email != undefined) {
      question_email_id = q_email.id;
      survey.respondent_data.email = this.answers.find(a => a.question_id == question_email_id).answer;
    }
    if (q_phone != undefined) {
      question_phone_id = q_phone.id;
      survey.respondent_data.phone = this.answers.find(a => a.question_id == question_phone_id).answer;
    }
  }
  /** format responses to register data in Firebase */
  formatPreviewData(respondent_uuid: string, survey_formated: any): PreviewData {

    let preview: PreviewData = null;

    this.answers.forEach((a: Answer) => {
      let finded_question: Question = null;
      this.survey_data.pages.every((p: QuestionPage) => {
        const find = p.questions.find((q: Question) => q.id == a.question_id);
        if (find != undefined) {
          finded_question = find;
          return false;
        } else return true;
      })
      // console.log("finded_question", finded_question);

      const add = {
        title: finded_question.title[this.trans.actualLang],
        help_text: finded_question.help_text != undefined ? finded_question.help_text[this.trans.actualLang] : null,
        mandatory: finded_question.mandatory,
        question_type: finded_question.type,
        validation_type: finded_question.validation_type != undefined ? finded_question.validation_type : null,
        motive: a.motive != undefined ? a.motive : '',
        question_id: a.question_id,
        other_text: a.other_text != undefined ? a.other_text : '',
        answer: a.answer,
        order: finded_question.order_preview != undefined ? finded_question.order_preview : finded_question.order
      }
      if (finded_question.validation_type == "5" || finded_question.type == 6) {
        if (moment.isMoment(add.answer)) add.answer = add.answer.toISOString();
        add.answer = new Date(add.answer).getTime();
      }
      if (finded_question.type == 3) {
        add['not_selected'] = finded_question.alternatives.filter((alt1: Alternative) => !a.answer.some((alt2: any) => alt2.id == alt1.id));
      }
      if (finded_question.type == 16 || finded_question.type == 30 || finded_question.type == 31) {
        add.answer = Object.assign({}, a.answer);
        delete add.answer.file;
        delete add.answer.base64;
        delete add.answer.file_ext;

        add.answer.url = survey_formated.answers.find(ans => ans.su_question_id == a.question_id).answer;
      }
      if (preview == null) {
        preview = {
          respondent_uuid: respondent_uuid,
          status: 0,
          created_at: moment().valueOf(),
          status_changed_at: null,
          banner: this.survey_data.logo,
          questions: [add],
          language: this.trans.actualLang,
          extra_data: this.survey_data.extra_data != undefined ? this.survey_data.extra_data : null,
          dedicated_delivery: this.survey_data.title[this.trans.actualLang].includes('Entrega Dedicada')
        }

      } else preview.questions.push(add);
    });

    return preview;
  }
  /**
   * check if survey has OTP question
   */
  hasOTPValidation() {
    return this.survey_data.validate_otp != undefined ? this.survey_data.validate_otp : false;
  }
  /**
   * reset all data from previous respondent
   */
  resetTemporarySurveyData() {
    // console.log("temporary answers will be deleted");    
    this.surveyLoaded = false;
    setTimeout(() => {
      this.surveyLoaded = true;
    }, 1);
    this.answers = [];
    this.current_page = this.survey_data.pages[0];
    this.current_page_index = 0;
    this.m_answers = 0;
    this.reset_progress.next(true);
    this.temp_answer_sub?.unsubscribe();
    this.dbService.deleteTemporaryAnswersBySurveyUUID();
    this.localStorageService.remove("temp-derivations-" + this.survey_data.id.toString())
  }
  /** clear survey properties and redirect to thanks page */
  finishSurvey() {
    this.router.navigate(['/thanks']).then(() => {
      this.resetTemporarySurveyData();
    });
  }
  /**
   * check if some of the temporary questions is not valid and return the value of the page in where it is
   * @returns page number
   */
  async getIncompletePageNOrder() {
    const n_page = await new Promise((res, rej) => {
      // mark as invalid all file type questions (16, 30, 31)
      this.answers.forEach((a: Answer) => {
        if (a.type == 16 || a.type == 30 || a.type == 31) a.valid = false;
      })
      this.answers.every((a: Answer, index: number) => {
        // console.log(a);
        const page = this.survey_data.pages[this.survey_data.pages.findIndex((p: QuestionPage) => p.n_order == a.page)];
        let quest_f: Question = page.questions.find((q: Question) => q.id == a.question_id);
        if (quest_f) {
          // console.log("invalid: " + !a.valid, "visibility: " + quest_f.visibility);
          if (quest_f.visibility) {
            if (!a.valid) {
              res(a.page);
              return false;
            }
          }
          if (index == this.answers.length - 1) {
            res(a.page)
            return false;
          }
        }
        return true;
      })
    })
    return n_page;
  }
  /**
 * control derivation logic depending on current answer saved
 * @param question_id question id with active derivation
 * @param page_origin actual page
 * @param page_derivation destination page
 */
  saveDerivation(question_id: number, page_origin: number, page_derivation: number) {

    const deriv_idx = this.derivations.findIndex(d => d.question_id == question_id);
    // console.log("page to derivate =", page_derivation);

    if (page_derivation != undefined) {

      if (deriv_idx == -1) {

        this.derivations.push({
          question_id: question_id,
          from: page_origin,
          to: page_derivation
        });

      } else this.derivations[deriv_idx].to = page_derivation;

    } else {
      if (deriv_idx != -1) this.derivations.splice(deriv_idx, 1);
    }
    if (this.derivations.length == 0) this.localStorageService.remove("temp-derivations-" + this.survey_data.id.toString());
    else this.localStorageService.set("temp-derivations-" + this.survey_data.id.toString(), JSON.stringify(this.derivations));
  }
  /**
   * return type question
   * @param id_type 
   * @returns 
   */
  getQuestionGroup(id_type: number) {
    return QuestionTypeGroups[id_type];
  }
  /**
   * check if survey has file type questions and has new data on it.
   * @returns 
   */
  async hasFileQuestions(answers: Array<Answer>) {
    return answers.some(a => [16, 30, 31].includes(a.type) && a.valid && a.answer["new_file"]);
  };
  /**
   * upload files to AWS, update answers with the new file URL.
   */
  async processFileAnswers(answers: Array<Answer>) {
    return new Promise(async (resolve, reject) => {

      const filter_answers = answers.filter((a: Answer) => {
        if (a.answer != null) return a.answer['file'] != undefined;
        else return false;
      });

      for (const [i, a] of filter_answers.entries()) {

        const q = this.getQuestionById(a.question_id);
        this.loadingScreenService.change_message.next(this.transPipe.transform('SURVEY.UPLOADING_FILE').replace('${question}', q.title[this.trans.actualLang]));

        const r = await this.awsService.uploadFile(a.answer.file, a.answer.file_name);

        // console.log(i, r);
        const real_ans_idx = this.answers.findIndex(ans => ans.question_id == a.question_id);
        if (r.Location != undefined) this.answers[real_ans_idx].answer["url"] = r.Location;
        if (r == null) resolve(false);
        if (i == filter_answers.length - 1) resolve(true);

      }

    })

  }
  /**
   * check if exist registered answers in indexedbd for the current survey
   */
  checkPendingAnswers() {
    return new Promise((resolve, reject) => {

      this.dbService.getPendingAnswers(this.survey_data.id_survey).then((data) => {
        // console.log("getPendingAnswers", data);
        if (data.length > 0) {
          this.pending_answers = data;
          // this.executePendingAnswersProcess();
          resolve(true);
        } else resolve(false);

      });

    })

  }
  /**
   * show modal to load pending answers
   */
  executePendingAnswersProcess() {

    const dialogSub = this.dialog.open(ModalProcessPendingAnswersComponent, {
      disableClose: true,
      panelClass: 'modal-process-pending-answers'
    }).afterClosed().subscribe((result) => {
      // console.log("ModalProcessPendingAnswersComponent", result);
      this.pending_answers.filter((pa) => pa.status == 1).forEach((pa) => {
        this.dbService.deletePendingAnswer(pa.id);
      })
      this.dbService.getPendingAnswers(this.survey_data.id_survey).then((data) => {
        this.pending_answers = data;
      });
      if (result.status) this.snack.openSnack(this.transPipe.transform('PROCESS_PENDING_ANSWERS.FINISHED'), this.transPipe.transform('COMMON.CLOSE'), 'right', 'top', ['snack-qservus-blue'], 5000);

      dialogSub.unsubscribe();
    })
  }

  /**
   * @author Matías Villar
   * @date 29-11-2022
   * @description verify if route is in preview mode or not, and save in is_preview surveyService public variable
   */
  isPreview() {
    let route = this.router.url.split('/')[1].toLowerCase()
    this.is_preview = (route === "p")
  }

  /**
	 * @author Matías Villar
	 * @date 08-02-2022
	 * @description check query params and set answer in suervey...
	 */
  async checkQueryParams(){
    if( this.qn ){
      //find question_id based on qn param (q_n)
      let flattened_questions = this.survey_data.pages.reduce((acc, val) => acc.concat(val.questions), [])
      let question_id = flattened_questions.find(q => q.q_n == this.qn).id

      this.partial_answers = {
        answers : [
          {
            question_id : question_id,
            answer      : this.a
          }
        ],
        extra_data : null
      }
      await this.setAnswersInSurvey(this.partial_answers)
    }
  }
  
  /**
	 * @author Matías Villar
	 * @date 12-12-2022
	 * @description Get Partial Answers from QServues api, save in storage and show in ui.
	 */
  async getPartialAnswers() : Promise<boolean>{
    this.answers = []
    let partial_answers : Partial_answers

    partial_answers = await this.qservusApiService.getPartialAnswers(this.uuid_respondent,this.api_domain)
    //validate if partial_answers has errors (means that survey has not partial answers) and if user has answers by url params (this.partial_answers).
    if( partial_answers.hasOwnProperty('errors') && this.partial_answers ){
      await this.setAnswersInSurvey(this.partial_answers)
    }else{
      //compare this.partial_answers with partial_answers, if are different, replace this.partial_answers.answers with partial_answers.answers
      if( this.partial_answers?.answers.length > 0 ){
        let exists_answer = false
        partial_answers.answers = partial_answers.answers.map( partial_answer => {
          let result = this.partial_answers.answers.find( answer => answer.question_id == partial_answer.question_id)
          if( result ){
            //save result as answer in partial_answers.answers
            exists_answer = true
            return result
          }else{
            return partial_answer
          }
        })
        if( !exists_answer ){
          //if answer not exists, add to array
          partial_answers.answers.push(this.partial_answers.answers[0])
        }
      }

      if( partial_answers.hasOwnProperty('answers') ){
        await this.setAnswersInSurvey(partial_answers)
        return true
      }else{
        return false
      }
    }
  }

  /**
	 * @author Matías Villar
	 * @date 09-02-2022
	 * @description Set answers in survey, from partial_answers object.
	 */
  async setAnswersInSurvey(partial_answers){
    let prueba : Array<any> = []

    try {
      this.survey_data.pages.forEach( page =>{
        page.questions.forEach( question =>{
          let answer : Answer
          let formattedAnswerArray = []
  
          switch (question.type) {
            case 1://Texto
            case 6:// fecha, hora
              //Encontrar la respuesta que proviene desde la API partial_answers.
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if(answer){
                prueba.push({
                  question        : question,
                  type            : question.type,
                  answer          : answer.answer,
                  n_order         : page.n_order,
                  matrix_type     : question.matrix_type,
                  alternatives_id : undefined,
                  other_text      : answer.answer_other ? answer.answer_other : undefined,
                  motive          : answer.answer_motive ? answer.answer_motive : undefined
                })
              }
              break;
            case 4: //selección única
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if(answer){
                question.alternatives.forEach( alternative =>{
                  if( this.findLabel(alternative.label, answer.answer) ){
                    alternative.hidden = false
                    alternative.visibility = true
                    prueba.push({
                      question        : question,
                      type            : question.type,
                      answer          : alternative,
                      n_order         : page.n_order,
                      matrix_type     : question.matrix_type,
                      alternatives_id : alternative.id,
                      other_text      : answer.answer_other ? answer.answer_other : undefined,
                      motive          : answer.answer_motive ? answer.answer_motive : undefined
                    })
                  }
                })
              }
              break;
            case 7: //seleccion simple
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if(answer){
                question.alternatives.forEach( alternative =>{
                  if( this.findLabel(alternative.label, answer.answer) ){
                    prueba.push({
                      question        : question,
                      type            : question.type,
                      answer          : alternative,
                      n_order         : page.n_order,
                      matrix_type     : question.matrix_type,
                      alternatives_id : alternative.id,
                      other_text      : answer.answer_other ? answer.answer_other : undefined,
                      motive          : answer.answer_motive ? answer.answer_motive : undefined
                    })
                  }
                })
              }
              break;
            case 3: //seleccion multiple
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if( answer ){
                formattedAnswerArray = []
                answer.answer.map( answer =>{
                  //Recorremos las alternativas
                  question.alternatives.map( alternative => {
                    if( this.findLabel(alternative.label, answer) ){
                      alternative['checked'] = true
                      formattedAnswerArray.push(alternative)
                    }
                  })
                })
                prueba.push({
                  question        : question,
                  type            : question.type,
                  answer          : formattedAnswerArray,
                  n_order         : page.n_order,
                  matrix_type     : question.matrix_type,
                  alternatives_id : undefined,
                  other_text      : answer.answer_other ? answer.answer_other : undefined,
                  motive          : answer.answer_motive ? answer.answer_motive : undefined
                })
              }
              break;
            case 5: //Estrellas
            case 9: //Likert
            case 10: //nps
            case 12: //Caras
            case 14: //nps
            case 17:
            case 19: //SCALE_NATIONAL_STARS
            case 29: //linea con opciones.
            case 32: //NPS.
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if( answer ){
                question.alternatives.forEach( alternative =>{
                  if( alternative.value == answer.answer ){
                    prueba.push({
                      question        : question,
                      type            : question.type,
                      answer          : answer.answer,
                      n_order         : page.n_order,
                      matrix_type     : question.matrix_type,
                      alternatives_id : alternative.id,
                      other_text      : answer.answer_other ? answer.answer_other : undefined,
                      motive          : answer.answer_motive ? answer.answer_motive : undefined
                    })
                  }
                })
              }
              break;
            case 18: //Ranking
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if( answer ){
                formattedAnswerArray = []
                //ordenar respuestas por ranking
                let answerRanking = answer.answer.sort((a, b) => a.order - b.order);
                //recorrer el partial answers por el orden...
                answerRanking.map( answer =>{
                  //encontramos si existe una alternativa que conincida con la respuesta
                  question.alternatives.map( alternative => {
                    this.findLabel(alternative.label, answer.alternative) ? formattedAnswerArray.push(alternative) : null
                  })
                })
  
                prueba.push({
                  question        : question,
                  type            : question.type,
                  answer          : formattedAnswerArray,
                  n_order         : page.n_order,
                  matrix_type     : question.matrix_type,
                  alternatives_id : undefined,
                  other_text      : answer.answer_other ? answer.answer_other : undefined,
                  motive          : answer.answer_motive ? answer.answer_motive : undefined
                })
              }
              break;
            case 24: ///términos y condiciones
              //Encontrar la respuesta que proviene desde la API partial_answers.
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if(answer){
                prueba.push({
                  question        : question,
                  type            : question.type,
                  answer          : answer.answer.toLowerCase() == 'true' ? true : false,
                  n_order         : page.n_order,
                  matrix_type     : question.matrix_type,
                  alternatives_id : undefined,
                  other_text      : answer.answer_other ? answer.answer_other : undefined,
                  motive          : answer.answer_motive ? answer.answer_motive : undefined
                })
              }
              break;
            case 27: //Si-No
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if( answer ){
                question.alternatives.forEach( alternative =>{
                  if( this.findLabel(alternative.label, answer.answer) ){
                    prueba.push({
                      question        : question,
                      type            : question.type,
                      answer          : alternative.value,
                      n_order         : page.n_order,
                      matrix_type     : question.matrix_type,
                      alternatives_id : alternative.id,
                      other_text      : answer.answer_other ? answer.answer_other : undefined,
                      motive          : answer.answer_motive ? answer.answer_motive : undefined
                    })
                  }
                })
              }
              break;
            case 100: //matriz selección multiple
              let answer_formatted = []
              if( question.matrix_selection_type == 2 ){
                //primero guardar en la respuesta la pregunta, con las alternativas como un aray vacío.
                question.matrix_questions.forEach( matrix_question =>{
                  answer_formatted.push({
                    alternative : [],
                    question : matrix_question, 
                    valid : true
                  })
                })
  
                //por cada answer formatted encontrar si tiene respuestas y agregarlas al arreglo
                answer_formatted.map( answer_formatted_elem =>{
                  let answer_formated_multi = []
                  answer = partial_answers.answers.find( answer => answer.question_id == answer_formatted_elem.question.id)
                  if( answer ){
                   //recorro el answer_formatted para buscar pregunta por pregunta si EXISTE alguna respuesta (ANSWER) respondida por el usuario.
                    answer_formatted_elem.question.alternatives.forEach( alternative =>{
                      //recorrer arreglo de respuesta.
                      answer.answer.forEach( user_answer =>{
                        if( this.findLabel(alternative.label, user_answer) ){
                          answer_formated_multi.push(alternative)
                        }else if( user_answer == '-2' ){
                          answer_formated_multi.push({
                            id: "-2",
                            label: question.no_apply_label,
                            value: "-2"
                          })
                        }
                      })
                    })
                    answer_formatted_elem.alternative = answer_formated_multi 
                  }
                })

                //check if answer_formated has selected alternatives
                let count_answer_formated = 0
                answer_formatted.forEach( answer_formatted_elem =>{
                  if( answer_formatted_elem.alternative.length > 0 ){
                    count_answer_formated++
                  }
                })
                
                if( count_answer_formated > 0){
                  prueba.push({
                    question        : question,
                    type            : question.type,
                    answer          : answer_formatted,
                    n_order         : page.n_order,
                    matrix_type     : question.matrix_type,
                    alternatives_id : undefined,
                    other_text      : undefined,
                    motive          : undefined
                  })
                }
              }else{ //matriz seleccion simple
                //recorrer las preguntas de la matriz,por cada pregunta evaluar si coincide el answer.question_id
                question.matrix_questions.forEach( matrix_question =>{
                  answer = partial_answers.answers.find(answer => answer.question_id == matrix_question.id)
                  if( answer ){
                    matrix_question.alternatives.forEach( alternative =>{
                      let has_alternative_selected = this.findLabel(alternative.label, answer.answer)
                      if( has_alternative_selected ){
                        answer_formatted.push({
                          alternative : alternative,
                          question : matrix_question
                        })
                      }
                    })
                  }
                })
                
                if( answer_formatted.length > 0){
                  prueba.push({
                    question        : question,
                    type            : question.type,
                    answer          : answer_formatted,
                    n_order         : page.n_order,
                    matrix_type     : question.matrix_type,
                    alternatives_id : undefined,
                    other_text      : (answer && answer.answer_other) ? answer.answer_other : undefined,
                    motive          : (answer && answer.answer_motive) ? answer.answer_motive : undefined
                  })
                }
              }
              break;
            case 30: //Archivo
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if(answer){
                prueba.push({
                  question        : question,
                  type            : question.type,
                  answer          : answer.file,
                  n_order         : page.n_order,
                  matrix_type     : question.matrix_type,
                  alternatives_id : undefined,
                  other_text      : answer.answer_other ? answer.answer_other : undefined,
                  motive          : answer.answer_motive ? answer.answer_motive : undefined
                })
              }
              break;
            case 16: //foto
              answer = partial_answers.answers.find( answer => answer.question_id == question.id)
              if(answer){
                prueba.push({
                  question        : question,
                  type            : question.type,
                  answer          : answer.image,
                  n_order         : page.n_order,
                  matrix_type     : question.matrix_type,
                  alternatives_id : undefined,
                  other_text      : answer.answer_other ? answer.answer_other : undefined,
                  motive          : answer.answer_motive ? answer.answer_motive : undefined
                })
              }
              break;
            default:
              console.log("Tipo de pregunta sin desarrollo:", question.type)
              break;
          }
        })
      })
  
      //only do if prueba.length > 0
      if( prueba.length > 0 ){
        for(let i=0;i<prueba.length;i++){
          let response = await this.createResponseFromPartialEndpoint(
            prueba[i].question.id,
            prueba[i].answer,
            prueba[i].n_order,
            prueba[i].type,
            prueba[i].matrix_type,
            prueba[i].other_text,
            prueba[i].motive,
            prueba[i].alternatives_id
          )
          if( response.answer ){
            await this.answers.push(response)
          }
        }
        // console.log('partial_answers.answers',partial_answers.answers)
        // console.log('this.survey_data',this.survey_data.pages[0].questions)
      
        await this.saveTemporaryAnswerInIndexedDB()
      }
      
    } catch (error) {
      console.log('catch',error)
      partial_answers = null
    }
  }

  /**
	 * @author Matías Villar
	 * @date 12-12-2022
	 * @description Create response from partial answer
   * @param 
	 */
  async createResponseFromPartialEndpoint( question_id: number, answer : Answer, n_order : number, type :number, matrix_type :number, other_text: string, motive : string, alternative_id ? : number) : Promise<Answer>{
    let question_answer : any
    
    if( type == 16 ){
      question_answer = await this.getImageFromUrl(answer)
    }else if( type == 30 ){
      question_answer = await this.getFileFromUrl(answer)
    }else{
      question_answer = answer
    }

    let response : Answer = {
      page            : n_order,
      question_id     : question_id,
      type            : type,
      answer          : question_answer,
      valid           : true
    }

    alternative_id    ? response['alternative_id']  = alternative_id : null
    matrix_type       ? response['matrix_type']     = matrix_type : null
    answer.motive     ? response['motive']          = answer.motive : null
    other_text        ? response['other_text']      = other_text : null
    motive            ? response['motive']        	= motive : ""
    return response
  }

  /**
	 * @author Matías Villar
	 * @date 14-12-2022
	 * @description show Modal requesting to continue with the survey or start a new one
	 */
  showModalPendingAnswer(){
    const diag = this.dialog.open(ModalConfirmComponent, {
      data: {
        title       : this.transPipe.transform('MODAL_LOAD_RESP.TITLE'),
        subtitle    : this.transPipe.transform('MODAL_LOAD_RESP.SUBTITLE'),
        message     : this.transPipe.transform('MODAL_LOAD_RESP.MESSAGE'),
        button_no   : this.transPipe.transform('MODAL_LOAD_RESP.START_A_NEW_ONE'),
        button_yes  : this.transPipe.transform('MODAL_LOAD_RESP.CONTINUE')
      },
      disableClose: true,
      panelClass: "modal-confirm"
    })
    diag.afterClosed().subscribe((resp) => {
      if (!resp) {
        this.resetTemporarySurveyData();
        this.saveAnswersInFirebase();
      }
    })
  }

  /**
	 * @author Matías Villar
	 * @date 15-12-2022
	 * @description transform url image to base64
   * @param imgUrl url of image
   * @returns { base64 : base 46 image type,
   * file : file type 'blob',
   * size : size of image,
   * type : type of image}
	 */
  async getImageFromUrl(imgUrl) {
    let res : any = await this.networkService.getImgBlob(imgUrl)
    let blob = new Blob([res], {type: res.type});
    let extension  = res.type.split('/')[1]
    const file = new File([blob], `image.${extension}`,{type: res.type})

    if( this.partialAnswersfileValidator(extension) ){
      return {
        base64        : await this.blobToBase64(blob),
        file          : file,
        size          : res.size,
        type          : res.type,
        file_name     : `image.${extension}`,
        file_prev_ext : "",
        file_ext      : extension,
        file_size     : res.size
      }
    }else{
      return null
    }
    
  }

  /**
	 * @author Matías Villar
	 * @date 02-02-2022
	 * @description transform url file to blob
   * @param fileUrl url of image
   * @returns { blob : base 46 image type,
  * file : file type 'blob',
  * size : size of image,
  * type : type of image}
  */
 async getFileFromUrl(fileUrl) {
   let res : any  = await this.networkService.getImgBlob(fileUrl)
   let type       = `application/${fileUrl.split('.').pop()}`
   let extension  = fileUrl.split('.').pop()
   let blob = new Blob([res], {type: type});
   const file = new File([blob], `file.${extension}`,{type: type})
   
   if( this.partialAnswersfileValidator(extension) ){
    return {
      base64        : await this.blobToBase64(blob),
      file          : file,
      file_ext      : extension,
      file_name     : `file.${extension}`,
      file_size     : res.size,
      file_type     : type,
      file_prev_ext : ""
      
    }
   }else{
    return null
   }
   
 }

  /**
	 * @author Matías Villar
	 * @date 15-12-2022
	 * @description Transform Blob file to base64.
   * @param file blob type file
   * @returns base64 file.
	 */
  async blobToBase64(file) {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
    return result_base64;
  }

  /**
	 * @author Matías Villar
	 * @date 19-12-2022
	 * @description Determine if partial_answer exists in option or answer label.
   * @param label Translate option or answer from question.
   * @param search partial_answer.
   * @returns Boolean null if not exists, true if exists.
	 */
  findLabel(label : Translate, search : string) : Boolean {
    let result = null
    Object.keys(label).forEach(key =>{
      if( label[key] == search ) result = true
    })
    return result
  }

  partialAnswersfileValidator(extension:string): Boolean{
    let fileExtension = extension.replace('.','')
    let types : Array<String> = ["doc","xls","xlsx","ppt","txt","pdf","csv","jpe","jpg","jpeg",
    "gif","png","bmp","icon","svg","svgz","tif","tiff","ai","drw","pct","xcf","psd","raw","webp"]
    return (types.find(extension => extension == fileExtension)) ? true : false
  }
}
