import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
// import { environment } from 'src/environments/environment';
// services
import { NetworkService } from './services/network.service';
import { UpdateWorkerService } from './services/update-worker.service';
// import { SnackbarService } from './services/snackbar.service';
// import { CalendlyService } from './services/calendly.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(@Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private networkService: NetworkService,
    private updateWorkerService: UpdateWorkerService,
    // private calendlyService: CalendlyService
    // private snackbarService: SnackbarService
  ) {

  }
  ngAfterContentInit(): void {
    // setTimeout(() => {
    //   this.setRandomBackground();
    // }, 500);
    // this.calendlyService.loadJsScript(this.renderer).then(() => {
      // this.calendlyService.openCalendlyWidget(environment.calendly.dgarsil);
    // })
    // this.snackbarService.openSnack('Prueba posición snackbar', 'OK', 'right', 'top', ['snack-qservus-blue']);
  }
  /**
   * set a random background for body element
   */
  setRandomBackground() {
    this.renderer.addClass(this.document.body, 'background-' + Math.floor(Math.random() * 13 + 1).toString());
  }

}
