import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// sentry
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

if (environment.production) {
  enableProdMode();
}

if( environment.sentry_enable){
  Sentry.init({
    dsn: "https://b3b310163ab44938af0b8f709bb4936d@o329695.ingest.sentry.io/5973973",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: environment.sentry_tracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: environment.sentry_environment,
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// platformBrowserDynamic().bootstrapModule(SurveyModule)
//   .catch(err => console.error(err));

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  // if ('serviceWorker' in navigator && environment.production) {
  if ('serviceWorker' in navigator) navigator.serviceWorker.register('ngsw-worker.js');
}).catch(err => console.log(err));