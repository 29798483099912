import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, pairwise, startWith } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterService {


  private history: Array<any> = [];
  public changeRoute: Subject<string> = new Subject();
  public url_params = null;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.pipe(startWith(new NavigationEnd(0, '/', '/')), filter(e => e instanceof NavigationEnd), pairwise())
      .subscribe((events) => {
        if (events[0]['url'] != '/') this.history = events;
        this.changeRoute.next(events[1]['url']);
      });
  }

  public subscribeRouting() {
    // this.router.events.pipe(startWith(new NavigationEnd(0, '/', '/')), filter(e => e instanceof NavigationEnd), pairwise())
    // .subscribe((events) => {
    //   if (events[0]['url'] != '/') this.history = events;
    //   this.changeRoute.next(events[1]['url']);
    // });
  }
  /**
   * get current url
   * @returns url
   */
  public getUrl(): string {
    return this.router.url;
  }
  /**
   * retrieve navigation history
   */
  public getHistory(): string[] {
    return this.history;
  }
  /** 
   * get url params
   */
  public getParams(): Promise<any> {
    return new Promise((res, rej) => {
      this.route.queryParams.subscribe(params => {
        // save params
        const props = Object.keys(params);
        if (props.length > 0) {
          props.forEach((prop: string, index: number) => {
            if (index == 0) {
              this.url_params = {
                [prop]: params[prop]
              };
            } else this.url_params[prop] = params[prop];
          })
        }
        res(params);
      }, error => rej(error));
    })
  }
  /**
   * redirect to another page
   * @param route 
   * @param params 
   */
  navigate(route, params?: any) {
    return new Promise((res, rej) => {
      if (params == undefined) this.router.navigate([route]).then( () => res(true))
      else this.router.navigate([route], { queryParams: params }).then( () => res(true));
    })
  }
  /**
   * clear navigation history
   */
  public clearHistory(): void {
    this.history = [];
  }
  /**
   * get preview route
   * @returns url
   */
  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '';
  }
  public reloadCurrentRoute() {
    let currentUrlWithParams = this.router.url;
    let params;
    let url = currentUrlWithParams;
    if (currentUrlWithParams.includes('?')) {
      const url_split = currentUrlWithParams.split("?");
      url = url_split[0];
      params = JSON.parse('{"' + url_split[1].replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
      // console.log(params);
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (params == undefined) this.router.navigate([url])
    else this.router.navigate([url], { queryParams: params });
  }
}
