import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'src/app/services/translate.service';

@Pipe({
  name: 'trans',
  pure: false
})
export class TransPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(key: any, vars = {}): any {
    //split key in keys
    let keys    : Array<string> = key.split('.')
    //find key in json array
    let result  = keys.reduce( (result,item) => (result = result ? result[item] : this.translate.data[item]),0 )
    let ret     = result || key

      if (!this.isEmpty(vars)) {
        ret = this.replaceVars(ret, vars);
      }
      return ret;
  }

  replaceVars(str, vars) {
    return str.replace("${"+Object.keys(vars) +"}", Object.values(vars));
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}

