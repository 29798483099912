import { Inject, Injectable, Optional } from "@angular/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";
import * as moment from "moment";
import { Moment } from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Injectable()
export class MomentUtcDateAdapter extends MomentDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  createDate(year: number, month: number, date: number): Moment {
    // Moment.js will create an invalid date if any of the components are out of bounds, but we
    // explicitly check each case so we can throw more descriptive errors.
    if (month < 0 || month > 11) {
      throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    let result = moment.utc({ year, month, date }).local().hour(0).minute(0).second(0);
    

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }
  // format(date: Moment, displayFormat: any): string {

  //   const offset = date.toDate().getTimezoneOffset() * 60000;
  //   const newDate = new Date(date.toDate().getTime() - offset);
  //   const days = newDate.getDate();
  //   const months = newDate.getMonth() + 1;
  //   const year = newDate.getFullYear();
  //   // return days + '-' + months + '-' + year;
  //   return year + '-' + months + '-' + days;
  // }
}

export class CustomDateAdapter extends NativeDateAdapter {
  public createDate(year: number, month: number, date: number): Date {
    const localDate = super.createDate(year, month, date);
    const offset = localDate.getTimezoneOffset() * 60000;
    return new Date(localDate.getTime() - offset); // utcDate
  }
  format(date: Date, displayFormat: any): string {
    const offset = date.getTimezoneOffset() * 60000;
    const newDate = new Date(date.getTime() - offset);
    const days = newDate.getDate();
    const months = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    // return days + '-' + months + '-' + year;
    return year + '-' + months + '-' + days;
  }
}