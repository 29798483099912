import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// services
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  title: string = "";
  subtitle: string = "";
  message: string = "";
  button_yes: string = "";
  button_no: string = "";
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.title = data.title;
    this.subtitle = data.subtitle;
    this.message = data.message;
    this.button_yes = data.button_yes;
    this.button_no = data.button_no;

  }

  ngOnInit(): void {
  }
  /**
   * return value of selected button to parent component
   * @param value 
   */
  emitAction(value: boolean) {
    this.dialogRef.close(value);
  }
}
